import React, { useEffect } from 'react'
import { useStore } from 'effector-react'

import {
  $ocxPerMinute,
  $timeElapsedPercent,
  $ocxAllocated,
  $ocxLeft,
  $delegators,
  $totalADAStaked,
  initFx,
  fetchPoolsFx,
  secondElapsed,
} from '../store/dashboard'
import MetaSEO from '../components/MetaSEO/MetaSEO'
import PageWrapper from '../components/PageWrapper/PageWrapper'
import PageContent from '../components/Dashboard/Dashboard'

import pageBg from '../img/dashboard/page-bg.png'
import { css } from 'styled-components'
import useWindowSize from '../helpers/utils/useWindowSize'

export default function Dashboard() {
  const seoTitle = 'OccamFi | OccamX ISPO'
  const seoDesc = ''

  useEffect(() => {
    initFx()

    const interval = setInterval(() => {
      secondElapsed()
    }, 1_000)

    return () => clearInterval(interval)
  }, [])

  useEffect(() => {
    fetchPoolsFx()

    const interval = setInterval(() => {
      fetchPoolsFx()
    }, 10_000)

    return () => clearInterval(interval)
  }, [])

  const ocxPerMinute = useStore($ocxPerMinute)
  const timeElapsedPercent = useStore($timeElapsedPercent)
  const ocxAllocated = useStore($ocxAllocated)
  const ocxLeft = useStore($ocxLeft)
  const delegators = useStore($delegators)
  const totalADAStaked = useStore($totalADAStaked)

  const { width } = useWindowSize()
  const headerXPadding = width >= 1280 ? 160 : 80

  return (
    <>
      <MetaSEO title={seoTitle} desc={seoDesc} />
      <PageWrapper
        bgImage={pageBg}
        backgroundProperties={css`
          background-position: top center;
          background-repeat: no-repeat;
        `}
        absoluteHeader
        lightHeaderLogo
        headerBurgerColor="#939BA9"
        headerXPadding={headerXPadding}
        headerBtn={false}
        footerEnabled={false}
      >
        <PageContent
          ocxPerMinute={ocxPerMinute}
          timeElapsedPercent={timeElapsedPercent}
          ocxAllocated={ocxAllocated}
          ocxLeft={ocxLeft}
          delegators={delegators}
          totalADAStaked={totalADAStaked}
        />
      </PageWrapper>
    </>
  )
}
