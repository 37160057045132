import React from 'react'
import styled from 'styled-components'

export default function Title({ children, className }) {
  return <H2 className={className}>{children}</H2>
}

const H2 = styled.h2`
  font-family: NeueHaasDisplay, sans-serif;
  font-weight: 100;
  font-size: 60px;
  line-height: 60px;

  margin-bottom: 20px;
  margin-top: 0;

  color: #ffffff;
  text-transform: capitalize;

  ${({ theme }) => theme.adaptive.dashboard.xl} {
    font-size: 45px;
    margin-bottom: 27px;
  }

  ${({ theme }) => theme.adaptive.dashboard.md} {
    font-size: 32px;
    line-height: 38px;
  }

  ${({ theme }) => theme.adaptive.dashboard.sm} {
    margin-bottom: 10px;
  }
`
