import React from 'react'

import styled from 'styled-components'

import delegatorsBg from '../../img/dashboard/delegators-bg.svg'

import Counter from './Counter'

export default function Delegators({ delegators }) {
  return (
    <StyledDelegators>
      <Counter small holes={6} end={delegators} />
      <DelegatorsText>Delegators</DelegatorsText>
    </StyledDelegators>
  )
}

const StyledDelegators = styled.div`
  width: 143px;
  height: 128px;

  padding: 30px 12px 20px 12px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  background-image: url('${delegatorsBg}');
  background-repeat: no-repeat;
  background-size: contain;

  ${({ theme }) => theme.adaptive.dashboard.xl} {
    width: 122px;
    height: 109px;

    padding: 25px 10px 17px 10px;
  }

  ${({ theme }) => theme.adaptive.dashboard.lg} {
    width: 107px;
    height: 96px;

    padding: 22px 9px 15px 9px;
  }

  ${({ theme }) => theme.adaptive.dashboard.sm} {
    width: 133px;
    height: 119px;

    padding: 27px 11px 20px 11px;
  }
`

const DelegatorsText = styled.div`
  font-weight: 300;
  color: #939ba9;

  ${({ theme }) => theme.adaptive.dashboard.xl} {
    font-size: 13px;
    line-height: 16px;
  }

  ${({ theme }) => theme.adaptive.dashboard.lg} {
    font-size: 12px;
    line-height: 14px;
  }

  ${({ theme }) => theme.adaptive.dashboard.sm} {
    font-size: 10px;
    line-height: 12px;
  }
`
