import React from 'react'
import styled from 'styled-components'
import { ReactComponent as CardanoImage } from 'img/new/protocol/cardano.svg'
import man from 'img/new/protocol/monk.png'
import interfaceImg from 'img/new/protocol/interface.svg'
import bg from 'img/new/protocol/bg.svg'
import ellipse from 'img/new/protocol/ellipse.svg'

const Protocol: React.FunctionComponent = () => {
  return (
    <Root>
      <About>
        <Blur />
        <EllipseBlur />
        <CardanoImageWrapper>
          <CardanoImage />
          <ManImage src={man} />
        </CardanoImageWrapper>
        <InterfaceImg />
        <AboutText>
          <Text type="large">
            Designed to deliver market leading launchpad capabilities, DEX
            tools, and liquidity pools.
          </Text>
        </AboutText>
      </About>
    </Root>
  )
}

export default Protocol

const Root = styled.div`
  position: relative;
  padding-top: 234px;
  z-index: 2;
  ${({ theme }) => theme.adaptive.newDesign.xl} {
    padding-top: 227px;
  }

  ${({ theme }) => theme.adaptive.newDesign.lg} {
    padding-top: 194px;
  }

  ${({ theme }) => theme.adaptive.newDesign.slg} {
    padding-top: 167px;
  }

  ${({ theme }) => theme.adaptive.newDesign.md} {
    padding-top: 124px;
  }

  ${({ theme }) => theme.adaptive.newDesign.sm} {
    padding-top: 115px;
  }
  ${({ theme }) => theme.adaptive.newDesign.smm} {
    padding-top: 132px;
  }

  ${({ theme }) => theme.adaptive.newDesign.xs} {
    padding-top: 100px;
  }
`

const About = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  ${({ theme }) => theme.adaptive.newDesign.sm} {
    flex-wrap: wrap;
  }
  ${({ theme }) => theme.adaptive.newDesign.smm} {
    justify-content: flex-start;
    flex-wrap: wrap;
  }
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 1700px;
    height: 427px;
    background: url('${bg}') center no-repeat;
    background-size: cover;
    ${({ theme }) => theme.adaptive.newDesign.xl} {
      width: 1488px;
      height: 373px;
    }
    ${({ theme }) => theme.adaptive.newDesign.lg} {
      width: 1275px;
      height: 320px;
    }
    ${({ theme }) => theme.adaptive.newDesign.slg} {
      width: 1098px;
      height: 275px;
    }
    ${({ theme }) => theme.adaptive.newDesign.md} {
      width: 815px;
      height: 204px;
    }
    ${({ theme }) => theme.adaptive.newDesign.sm} {
      content: unset;
    }
  }
`

const InterfaceImg = styled.div`
  position: relative;
  width: 491px;
  height: 402px;
  margin-top: -25px;
  margin-left: 187px;
  background: url('${interfaceImg}') center no-repeat;
  background-size: cover;
  ${({ theme }) => theme.adaptive.newDesign.xl} {
    width: 441px;
    height: 362px;
    margin-top: -25px;
    margin-left: 159px;
  }
  ${({ theme }) => theme.adaptive.newDesign.lg} {
    width: 380px;
    height: 311px;
    margin-top: -22px;
    margin-left: 134px;
  }
  ${({ theme }) => theme.adaptive.newDesign.slg} {
    width: 325px;
    height: 266px;
    margin-top: -20px;
    margin-left: 118px;
  }
  ${({ theme }) => theme.adaptive.newDesign.md} {
    width: 242px;
    height: 198px;
    margin-top: -14px;
    margin-left: 87px;
  }
  ${({ theme }) => theme.adaptive.newDesign.sm} {
    order: 2;
    width: 251px;
    height: 205px;
    margin-top: 0;
    margin-left: 19px;
  }
  ${({ theme }) => theme.adaptive.newDesign.smm} {
    position: absolute;
    top: 0;
    left: 220px;
    margin-left: 0;
  }
  ${({ theme }) => theme.adaptive.newDesign.xs} {
    top: 0;
    left: 190px;
    width: 217px;
    height: 178px;
  }
`

const Text = styled.p<{ type?: string }>`
  width: 465px;
  font-weight: 200;
  font-size: ${({ type }) => (type === 'large' ? '35px' : '24px')};
  line-height: 140%;
  opacity: ${({ type }) => (type === 'large' ? 1 : 0.5)};
  color: #fff;
  margin: 0;

  ${({ theme }) => theme.adaptive.newDesign.xl} {
    width: 405px;
    font-size: ${({ type }) => (type === 'large' ? '30px' : '21px')};
  }

  ${({ theme }) => theme.adaptive.newDesign.lg} {
    width: 348px;
    font-size: ${({ type }) => (type === 'large' ? '26px' : '18px')};
  }

  ${({ theme }) => theme.adaptive.newDesign.slg} {
    width: 297px;
    font-size: ${({ type }) => (type === 'large' ? '22px' : '15px')};
  }

  ${({ theme }) => theme.adaptive.newDesign.md} {
    width: 229px;
    font-size: ${({ type }) => (type === 'large' ? '17px' : '12px')};
  }

  ${({ theme }) => theme.adaptive.newDesign.sm} {
    width: 270px;
    font-size: ${({ type }) => (type === 'large' ? '19px' : '14px')};
  }

  ${({ theme }) => theme.adaptive.newDesign.smm} {
    width: 194px;
    font-size: ${({ type }) => (type === 'large' ? '20px' : '16px')};
  }

  ${({ theme }) => theme.adaptive.newDesign.xs} {
    width: 160px;
    font-size: ${({ type }) => (type === 'large' ? '16px' : '13px')};
  }

  a {
    outline: none;
    color: #fff;
  }
`

const AboutText = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 71px;
  ${({ theme }) => theme.adaptive.newDesign.xl} {
    padding-top: 67px;
    margin-left: 55px;
  }
  ${({ theme }) => theme.adaptive.newDesign.lg} {
    padding-top: 58px;
    margin-left: 47px;
  }
  ${({ theme }) => theme.adaptive.newDesign.slg} {
    padding-top: 50px;
    margin-left: 41px;
  }
  ${({ theme }) => theme.adaptive.newDesign.md} {
    padding-top: 37px;
    margin-left: 30px;
  }
  ${({ theme }) => theme.adaptive.newDesign.sm} {
    padding-top: 0;
    margin-left: 0;
  }

  ${Text} {
    &:first-child {
      margin-bottom: 24px;
      ${({ theme }) => theme.adaptive.newDesign.xl} {
        margin-bottom: 21px;
      }

      ${({ theme }) => theme.adaptive.newDesign.lg} {
        margin-bottom: 18px;
      }

      ${({ theme }) => theme.adaptive.newDesign.slg} {
        margin-bottom: 15px;
      }

      ${({ theme }) => theme.adaptive.newDesign.md} {
        margin-bottom: 11px;
      }

      ${({ theme }) => theme.adaptive.newDesign.sm} {
        margin-bottom: 10px;
      }

      ${({ theme }) => theme.adaptive.newDesign.smm} {
        margin-bottom: 15px;
      }
    }
  }
`

const ManImage = styled.img`
  position: absolute;
  left: 87px;
  top: 33px;
  width: 362px;

  ${({ theme }) => theme.adaptive.newDesign.xl} {
    left: 77px;
    top: 23px;
    width: 314px;
  }
  ${({ theme }) => theme.adaptive.newDesign.lg} {
    left: 66px;
    top: 34px;
    width: 252px;
  }
  ${({ theme }) => theme.adaptive.newDesign.slg} {
    left: 56px;
    top: 1px;
    width: 252px;
  }
  ${({ theme }) => theme.adaptive.newDesign.md} {
    left: 42px;
    top: 1px;
    width: 188px;
  }
  ${({ theme }) => theme.adaptive.newDesign.sm} {
    left: 5px;
    top: 75px;
    width: 282px;
    transform: scale(-1, 1);
  }
  ${({ theme }) => theme.adaptive.newDesign.xs} {
    left: 4px;
    top: 58px;
    width: 220px;
  }
`

const CardanoImageWrapper = styled.div`
  position: relative;
  width: 343px;
  height: 320px;
  margin-top: 35px;

  ${({ theme }) => theme.adaptive.newDesign.xl} {
    width: 280px;
    height: 300px;
    margin-top: 32px;
  }
  ${({ theme }) => theme.adaptive.newDesign.lg} {
    width: 240px;
    height: 257px;
    margin-top: 26px;
  }
  ${({ theme }) => theme.adaptive.newDesign.slg} {
    width: 207px;
    height: 221px;
    margin-top: 23px;
  }
  ${({ theme }) => theme.adaptive.newDesign.md} {
    width: 153px;
    height: 164px;
    margin-top: 17px;
  }
  ${({ theme }) => theme.adaptive.newDesign.sm} {
    order: 3;
    width: 316px;
    height: 339px;
    margin: 0 auto;
    margin-top: 34px;
  }

  ${({ theme }) => theme.adaptive.newDesign.xs} {
    width: 245px;
    height: 265px;
    margin: 0 auto;
    margin-top: -10px;
  }
  svg {
    width: 100%;
    height: 100%;
  }
`

const Blur = styled.div`
  position: absolute;
  top: -132px;
  right: 490px;
  width: 443px;
  height: 443px;
  background: #6ca7ff;
  opacity: 0.5;
  filter: blur(300px);
  transform: translate3d(0, 0, 0);
  ${({ theme }) => theme.adaptive.newDesign.xl} {
    top: -116px;
    right: 423px;
    width: 388px;
    height: 388px;
    filter: blur(260px);
  }
  ${({ theme }) => theme.adaptive.newDesign.lg} {
    top: -99px;
    right: 363px;
    width: 332px;
    height: 332px;
    filter: blur(225px);
  }
  ${({ theme }) => theme.adaptive.newDesign.slg} {
    top: -85px;
    right: 313px;
    width: 286px;
    height: 286px;
    filter: blur(173px);
  }
  ${({ theme }) => theme.adaptive.newDesign.md} {
    top: -63px;
    right: 232px;
    width: 212px;
    height: 212px;
    filter: blur(140px);
  }
  ${({ theme }) => theme.adaptive.newDesign.sm} {
    top: -150px;
    right: 340px;
  }
  ${({ theme }) => theme.adaptive.newDesign.smm} {
    top: -202px;
    right: 245px;
  }
  ${({ theme }) => theme.adaptive.newDesign.xs} {
    top: -157px;
    right: 196px;
    width: 166px;
    height: 166px;
    filter: blur(120px);
  }
`

const EllipseBlur = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: -20%;
  width: 1579px;
  height: 1528px;
  background: url('${ellipse}') center no-repeat;
  background-size: cover;
  filter: blur(220px);
  transform: translate3d(0, 0, 0);
  ${({ theme }) => theme.adaptive.newDesign.xl} {
    width: 1382px;
    height: 1338px;
    filter: blur(193px);
  }
  ${({ theme }) => theme.adaptive.newDesign.lg} {
    width: 1185px;
    height: 1147px;
    filter: blur(165px);
  }
  ${({ theme }) => theme.adaptive.newDesign.slg} {
    width: 1021px;
    height: 988px;
    filter: blur(142px);
  }
  ${({ theme }) => theme.adaptive.newDesign.md} {
    width: 757px;
    height: 733px;
    filter: blur(105px);
  }
  ${({ theme }) => theme.adaptive.newDesign.sm} {
    top: unset;
    left: unset;
    transform: unset;
    bottom: -5%;
    right: -25%;
    width: 816px;
    height: 789px;
  }
  ${({ theme }) => theme.adaptive.newDesign.smm} {
    bottom: -15%;
    right: -80%;
    filter: blur(82px);
  }
  ${({ theme }) => theme.adaptive.newDesign.xs} {
    bottom: -25%;
    width: 636px;
    height: 616px;
  }
`
