import { ReactComponent as EmurgoLogo } from '../img/partners/emurgo.svg'
import { ReactComponent as CfundLogo } from '../img/partners/cfund.svg'
import { ReactComponent as ScalableLogo } from '../img/partners/scalable.svg'
import { ReactComponent as CardanoLogo } from '../img/partners/cardano.svg'
import { ReactComponent as Coin360Logo } from '../img/partners/coin360.svg'
import { ReactComponent as CvvcLogo } from '../img/partners/cvvc.svg'

export default [
  {
    link: 'https://emurgo.io/',
    getLogo: (fill: string | undefined) => <EmurgoLogo fill={fill} />,
  },
  {
    link: 'https://cfund.vc/',
    getLogo: (fill: string | undefined) => <CfundLogo fill={fill} />,
  },
  {
    getLogo: (fill: string | undefined) => <CvvcLogo fill={fill} />,
    link: 'https://cvvc.com/',
  },
  {
    getLogo: (fill: string | undefined) => <Coin360Logo fill={fill} />,
    link: 'https://coin360.com/',
  },
  {
    getLogo: (fill: string | undefined) => <CardanoLogo fill={fill} />,
    link: 'https://cardano.org/',
  },
  {
    getLogo: (fill: string | undefined) => <ScalableLogo fill={fill} />,
    link: 'https://scalablesolutions.io/',
  },
]
