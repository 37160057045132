import React from 'react'
import styled from 'styled-components'
import { ReactComponent as BtnBg } from 'img/new/btn-bg.svg'
import { ReactComponent as BtnBgHover } from 'img/new/btn-bg-hover.svg'
import { DAPP_LINK } from 'configs/app.config'

const Button: React.FunctionComponent = () => {
  return (
    <ButtonCont href={DAPP_LINK} target={'_blank'}>
      <Bg>
        <BtnBg />
      </Bg>
      <BgHover>
        <BtnBgHover />
      </BgHover>
      <BtnText>Open dApp</BtnText>
    </ButtonCont>
  )
}

export default Button

const Bg = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 1;
  transition: 0.4s;
  svg {
    width: 100%;
    height: 100%;
  }
`

const BgHover = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  transition: 0.4s;
  svg {
    width: 100%;
    height: 100%;
  }
`

const BtnText = styled.div`
  position: relative;
  font-weight: 400;
  font-size: 18px;
  line-height: 100%;
  text-align: left;
  color: #91e2eb;
  ${({ theme }) => theme.adaptive.newDesign.xl} {
    font-size: 16px;
  }

  ${({ theme }) => theme.adaptive.newDesign.lg} {
    font-size: 14px;
  }

  ${({ theme }) => theme.adaptive.newDesign.md} {
    font-size: 12px;
  }

  ${({ theme }) => theme.adaptive.newDesign.sm} {
    font-size: 14px;
  }
  ${({ theme }) => theme.adaptive.newDesign.xs} {
    font-size: 12px;
  }
`

const ButtonCont = styled.a`
  position: relative;
  display: flex;
  justify-content: start;
  align-items: center;
  width: 232px;
  height: 53px;
  padding: 0 25px;
  ${({ theme }) => theme.adaptive.newDesign.xl} {
    width: 203px;
    height: 46px;
    padding: 0 22px;
  }

  ${({ theme }) => theme.adaptive.newDesign.lg} {
    width: 174px;
    height: 40px;
    padding: 0 19px;
  }

  ${({ theme }) => theme.adaptive.newDesign.slg} {
    width: 150px;
    height: 34px;
    padding: 0 16px;
  }

  ${({ theme }) => theme.adaptive.newDesign.md} {
    width: 111px;
    height: 25px;
    padding: 0 12px;
  }
  &:hover {
    ${Bg} {
      opacity: 0;
    }
    ${BgHover} {
      opacity: 1;
    }
  }
`
