import React, { useRef } from 'react'
import styled from 'styled-components'
import { CSSTransition } from 'react-transition-group'

interface BurgerProps {
  onBurgerClick: () => void
  animationStart: boolean
  active: boolean
}

const HeaderBurger: React.FunctionComponent<BurgerProps> = (props) => {
  const burgerNode = useRef(null)

  const toggleBurger = () => {
    console.log('dsadsadsa')
    props.onBurgerClick()
  }

  return (
    <BurgerAnimayionStyles>
      <CSSTransition
        nodeRef={burgerNode}
        in={props.animationStart}
        classNames="burger-anim"
        timeout={300}
      >
        <Burger
          onClick={toggleBurger}
          ref={burgerNode}
          className={`burger`}
          active={props.active}
        >
          <BurgerLine />
          <BurgerLine />
          <BurgerLine />
        </Burger>
      </CSSTransition>
    </BurgerAnimayionStyles>
  )
}

export default HeaderBurger

const BurgerLine = styled.div`
  position: relative;
  width: 100%;
  height: 1px;
  cursor: pointer;
  margin-bottom: 7px;
  transition: transform 0.3s ease;
  z-index: 2;

  ${({ theme }) => theme.adaptive.newDesign.xs} {
    margin-bottom: 5px;
  }

  &:last-child {
    margin-bottom: 0;
  }
`

const Burger = styled.div<{ active: boolean }>`
  position: relative;
  width: 25px;
  cursor: pointer;
  z-index: 5;

  ${({ theme }) => theme.adaptive.newDesign.xs} {
    width: 20px;
  }

  ${BurgerLine} {
    background-color: ${({ active }) => (active ? '#FFF' : '#FFF')};
  }
`

const ROTATE_LINE_DEG = '45deg'
const TRANSLATE_LINE_DEG = '9px'
const TRANSLATE_LINE_DEG_LAST = '7px'
const TRANSLATE_LINE_DEG_LAST_XS = '6px'
const TRANSLATE_LINE_DEG_MOB = '6px'

const BurgerAnimayionStyles = styled.div`
  .burger {
    &.burger-anim-enter-active {
      div {
        &:nth-child(1) {
          transform: translateY(${TRANSLATE_LINE_DEG});

          ${({ theme }) => theme.adaptive.newDesign.xs} {
            transform: translateY(${TRANSLATE_LINE_DEG_MOB});
          }
        }

        &:nth-child(3) {
          transform: translateY(-${TRANSLATE_LINE_DEG_LAST});

          ${({ theme }) => theme.adaptive.newDesign.xs} {
            transform: translateY(-${TRANSLATE_LINE_DEG_LAST_XS});
          }
        }
      }
    }

    &.burger-anim-enter-done {
      div {
        &:nth-child(1) {
          transform: translateY(${TRANSLATE_LINE_DEG})
            rotate(-${ROTATE_LINE_DEG});

          ${({ theme }) => theme.adaptive.newDesign.xs} {
            transform: translateY(${TRANSLATE_LINE_DEG_MOB})
              rotate(-${ROTATE_LINE_DEG});
          }
        }

        &:nth-child(2) {
          opacity: 0;
        }

        &:nth-child(3) {
          transform: translateY(-${TRANSLATE_LINE_DEG_LAST})
            rotate(${ROTATE_LINE_DEG});

          ${({ theme }) => theme.adaptive.newDesign.xs} {
            transform: translateY(-${TRANSLATE_LINE_DEG_LAST_XS})
              rotate(${ROTATE_LINE_DEG});
          }
        }
      }
    }

    &.burger-anim-exit-active {
      div {
        &:nth-child(1) {
          transform: rotate(0) translateY(${TRANSLATE_LINE_DEG});

          ${({ theme }) => theme.adaptive.newDesign.xs} {
            transform: rotate(0) translateY(${TRANSLATE_LINE_DEG_MOB});
          }
        }

        &:nth-child(2) {
          opacity: 0;
        }

        &:nth-child(3) {
          transform: rotate(0) translateY(-${TRANSLATE_LINE_DEG_LAST});

          ${({ theme }) => theme.adaptive.newDesign.xs} {
            transform: rotate(0) translateY(-${TRANSLATE_LINE_DEG_LAST_XS});
          }
        }
      }
    }

    div {
      transform-origin: center center;
    }
  }
`
