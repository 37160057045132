import React from 'react'
import styled from 'styled-components'
import { OCCAM_LINK } from 'configs/app.config'

function Logo() {
  return (
    <Root>
      <Text>Occam</Text>
      <TextX>X</TextX>
    </Root>
  )
}

export default Logo

const Root = styled.div`
  display: flex;
  align-items: center;
  font-family: 'RPBrawl';
  color: #ffffff;
  text-transform: uppercase;
`

const Text = styled.span`
  font-size: 36px;

  ${({ theme }) => theme.adaptive.newDesign.xl} {
    font-size: 32px;
  }

  ${({ theme }) => theme.adaptive.newDesign.lg} {
    font-size: 27px;
  }

  ${({ theme }) => theme.adaptive.newDesign.slg} {
    font-size: 23px;
  }

  ${({ theme }) => theme.adaptive.newDesign.md} {
    font-size: 17px;
  }
`

const TextX = styled.span`
  font-size: 60px;

  ${({ theme }) => theme.adaptive.newDesign.xl} {
    font-size: 53px;
  }

  ${({ theme }) => theme.adaptive.newDesign.lg} {
    font-size: 45px;
  }

  ${({ theme }) => theme.adaptive.newDesign.slg} {
    font-size: 39px;
  }

  ${({ theme }) => theme.adaptive.newDesign.md} {
    font-size: 29px;
  }
`
