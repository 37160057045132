import React from 'react'
import MetaTags from 'react-meta-tags'

export default function MetaSEO({ title, desc }) {
  return (
    <MetaTags>
      <title>{title}</title>
      <meta name="description" content={desc} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={desc} />
      <meta property="og:image" content="/preview.png" />
    </MetaTags>
  )
}
