import React from 'react'
import styled from 'styled-components'
import arrowImg from 'img/new/main/arrow.svg'
import monk from 'img/new/main/monk.png'
import circles from 'img/new/main/circles.svg'
import ellipse from 'img/new/main/elipse.svg'
import circlesSm from 'img/new/main/circles-sm.svg'
import useWindowSize from 'helpers/utils/useWindowSize'
import Button from '../../Button'

const Main: React.FunctionComponent = () => {
  const { width } = useWindowSize()

  return (
    <Root>
      <Monk>
        <Circles />
      </Monk>
      <EllipseBlur />
      <EllipseBlur2 />
      <TitleWrap>
        <Border />
        <SubTitle>The OccamX Protocol</SubTitle>
        <Title>Earn, Swap and Provide Liquidity with Interchain DEX</Title>
      </TitleWrap>
      <BtnRow>
        <Text>Interchain DeFi landscape</Text>
        <Arrow />
        <ButtonWrap href={'https://app.occam-x.fi'} target={'_blank'}>
          <BtnText>
            Launch <span>OccamX</span>
          </BtnText>
        </ButtonWrap>
      </BtnRow>
    </Root>
  )
}

export default Main

const Root = styled.div`
  position: relative;
  padding-top: 165px;
  z-index: 3;
  ${({ theme }) => theme.adaptive.newDesign.xl} {
    padding-top: 138px;
  }

  ${({ theme }) => theme.adaptive.newDesign.lg} {
    padding-top: 124px;
  }

  ${({ theme }) => theme.adaptive.newDesign.slg} {
    padding-top: 107px;
  }

  ${({ theme }) => theme.adaptive.newDesign.md} {
    padding-top: 78px;
  }

  ${({ theme }) => theme.adaptive.newDesign.sm} {
    padding-top: 94px;
  }

  ${({ theme }) => theme.adaptive.newDesign.xs} {
    padding-top: 74px;
  }
`

const TitleWrap = styled.div`
  position: relative;
  width: 1060px;
  padding-left: 78px;
  ${({ theme }) => theme.adaptive.newDesign.xl} {
    width: 915px;
    padding-left: 68px;
  }
  ${({ theme }) => theme.adaptive.newDesign.lg} {
    width: 785px;
    padding-left: 59px;
  }
  ${({ theme }) => theme.adaptive.newDesign.slg} {
    width: 675px;
    padding-left: 50px;
  }
  ${({ theme }) => theme.adaptive.newDesign.md} {
    width: 500px;
    padding-left: 37px;
  }
  ${({ theme }) => theme.adaptive.newDesign.sm} {
    width: 397px;
    margin: 0 auto;
    padding-left: 0;
  }
  ${({ theme }) => theme.adaptive.newDesign.smm} {
    width: 100%;
  }
`

const SubTitle = styled.div`
  font-style: italic;
  font-weight: 300;
  font-size: 38px;
  line-height: 46px;
  color: #ffffff;
  opacity: 0.8;
  margin-bottom: 36px;
  ${({ theme }) => theme.adaptive.newDesign.xl} {
    font-size: 33px;
    line-height: 40px;
    margin-bottom: 31px;
  }
  ${({ theme }) => theme.adaptive.newDesign.lg} {
    font-size: 28px;
    line-height: 34px;
    margin-bottom: 27px;
  }
  ${({ theme }) => theme.adaptive.newDesign.slg} {
    font-size: 24px;
    line-height: 29px;
    margin-bottom: 21px;
  }
  ${({ theme }) => theme.adaptive.newDesign.md} {
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 16px;
  }
  ${({ theme }) => theme.adaptive.newDesign.sm} {
    text-align: center;
    margin-bottom: 299px;
  }
  ${({ theme }) => theme.adaptive.newDesign.xs} {
    font-size: 14px;
    line-height: 17px;
    margin-bottom: 232px;
  }
`

const Title = styled.div`
  font-weight: 200;
  font-size: 89px;
  line-height: 110%;
  align-items: center;
  letter-spacing: -0.02em;
  color: #ffffff;
  ${({ theme }) => theme.adaptive.newDesign.xl} {
    font-size: 78px;
  }
  ${({ theme }) => theme.adaptive.newDesign.lg} {
    font-size: 67px;
  }
  ${({ theme }) => theme.adaptive.newDesign.slg} {
    font-size: 58px;
  }
  ${({ theme }) => theme.adaptive.newDesign.md} {
    font-size: 43px;
  }
  ${({ theme }) => theme.adaptive.newDesign.sm} {
    text-align: center;
    font-size: 32px;
  }
  ${({ theme }) => theme.adaptive.newDesign.xs} {
    font-size: 25px;
  }
`

const Border = styled.div`
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 1px;
  height: 398px;
  background: #fff;
  opacity: 0.2;
  ${({ theme }) => theme.adaptive.newDesign.xl} {
    height: 348px;
  }
  ${({ theme }) => theme.adaptive.newDesign.lg} {
    height: 299px;
  }
  ${({ theme }) => theme.adaptive.newDesign.slg} {
    height: 257px;
  }
  ${({ theme }) => theme.adaptive.newDesign.md} {
    height: 191px;
  }
  ${({ theme }) => theme.adaptive.newDesign.sm} {
    display: none;
  }
`

const BtnRow = styled.div`
  padding-left: 78px;
  display: flex;
  align-items: center;
  margin-top: 72px;
  z-index: 5;
  ${({ theme }) => theme.adaptive.newDesign.xl} {
    padding-left: 62px;
    margin-top: 62px;
  }
  ${({ theme }) => theme.adaptive.newDesign.lg} {
    padding-left: 59px;
    margin-top: 53px;
  }
  ${({ theme }) => theme.adaptive.newDesign.slg} {
    padding-left: 50px;
    margin-top: 46px;
  }
  ${({ theme }) => theme.adaptive.newDesign.md} {
    padding-left: 37px;
    margin-top: 36px;
  }
  ${({ theme }) => theme.adaptive.newDesign.sm} {
    width: 100%;
    justify-content: center;
    padding-left: 0;
    margin-top: 36px;
  }
  ${({ theme }) => theme.adaptive.newDesign.xs} {
    margin-top: 28px;
  }
`

const Text = styled.div`
  font-weight: 400;
  font-size: 26px;
  line-height: 31px;
  color: #d0d0d0;
  opacity: 0.7;
  ${({ theme }) => theme.adaptive.newDesign.xl} {
    font-size: 23px;
    line-height: 28px;
  }
  ${({ theme }) => theme.adaptive.newDesign.lg} {
    font-size: 20px;
    line-height: 24px;
  }
  ${({ theme }) => theme.adaptive.newDesign.slg} {
    font-size: 17px;
    line-height: 20px;
  }
  ${({ theme }) => theme.adaptive.newDesign.md} {
    font-size: 14px;
    line-height: 17px;
  }
  ${({ theme }) => theme.adaptive.newDesign.sm} {
    display: none;
  }
`

const Arrow = styled.div`
  width: 17px;
  height: 44px;
  background: url('${arrowImg}') center no-repeat;
  background-size: cover;
  margin-left: 27px;
  ${({ theme }) => theme.adaptive.newDesign.xl} {
    width: 15px;
    height: 39px;
    margin-left: 21px;
  }
  ${({ theme }) => theme.adaptive.newDesign.lg} {
    width: 13px;
    height: 33px;
    margin-left: 16px;
  }
  ${({ theme }) => theme.adaptive.newDesign.slg} {
    width: 11px;
    height: 28px;
    margin-left: 15px;
  }
  ${({ theme }) => theme.adaptive.newDesign.md} {
    width: 8px;
    height: 21px;
    margin-left: 6px;
  }
  ${({ theme }) => theme.adaptive.newDesign.sm} {
    display: none;
  }
`

const ButtonWrap = styled.a`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 356px;
  height: 75px;
  background: linear-gradient(180deg, #639fe7 0%, #508ce7 100%);
  border-radius: 10px;
  margin-left: 62px;
  cursor: pointer;
  z-index: 5;
  ${({ theme }) => theme.adaptive.newDesign.xl} {
    width: 312px;
    height: 66px;
    border-radius: 9px;
    margin-left: 54px;
  }
  ${({ theme }) => theme.adaptive.newDesign.lg} {
    width: 267px;
    height: 56px;
    border-radius: 8px;
    margin-left: 46px;
  }
  ${({ theme }) => theme.adaptive.newDesign.slg} {
    width: 230px;
    height: 48px;
    border-radius: 7px;
    margin-left: 40px;
  }
  ${({ theme }) => theme.adaptive.newDesign.md} {
    width: 171px;
    height: 36px;
    border-radius: 5px;
    margin-left: 30px;
  }
  ${({ theme }) => theme.adaptive.newDesign.sm} {
    width: 217px;
    height: 46px;
    border-radius: 5px;
    margin-left: 0;
  }
  ${({ theme }) => theme.adaptive.newDesign.xs} {
    width: 169px;
    height: 36px;
    border-radius: 4px;
  }
`

const BtnText = styled.div`
  font-weight: 400;
  font-size: 38px;
  line-height: 100%;
  text-align: center;
  letter-spacing: -0.02em;
  color: rgba(255, 255, 255, 0.9);
  ${({ theme }) => theme.adaptive.newDesign.xl} {
    font-size: 33px;
  }
  ${({ theme }) => theme.adaptive.newDesign.lg} {
    font-size: 28px;
  }
  ${({ theme }) => theme.adaptive.newDesign.slg} {
    font-size: 24px;
  }
  ${({ theme }) => theme.adaptive.newDesign.md} {
    font-size: 18px;
  }
  ${({ theme }) => theme.adaptive.newDesign.sm} {
    font-size: 18px;
  }
  ${({ theme }) => theme.adaptive.newDesign.xs} {
    font-size: 14px;
  }
  span {
    font-weight: 500;
  }
`

const Monk = styled.div`
  position: absolute;
  top: 56px;
  right: -40px;
  width: 772px;
  height: 772px;
  background: url('${monk}') center no-repeat;
  background-size: cover;
  ${({ theme }) => theme.adaptive.newDesign.xl} {
    top: 30px;
    width: 675px;
    height: 675px;
    right: -50px;
  }
  ${({ theme }) => theme.adaptive.newDesign.lg} {
    top: 30px;
    width: 579px;
    height: 579px;
    right: -40px;
  }
  ${({ theme }) => theme.adaptive.newDesign.slg} {
    top: 30px;
    width: 499px;
    height: 499px;
    right: -40px;
  }
  ${({ theme }) => theme.adaptive.newDesign.md} {
    top: 14px;
    width: 370px;
    height: 370px;
    right: -30px;
  }
  ${({ theme }) => theme.adaptive.newDesign.sm} {
    top: 80px;
    width: 391px;
    height: 391px;
    right: unset;
    left: 50%;
    transform: translateX(-50%);
  }
  ${({ theme }) => theme.adaptive.newDesign.xs} {
    top: 63px;
    width: 306px;
    height: 306px;
  }
`

const Circles = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 3200px;
  height: 3200px;
  background: url('${circles}') center no-repeat;
  background-size: cover;
  ${({ theme }) => theme.adaptive.newDesign.xl} {
    width: 2800px;
    height: 2800px;
  }
  ${({ theme }) => theme.adaptive.newDesign.lg} {
    width: 2400px;
    height: 2400px;
  }
  ${({ theme }) => theme.adaptive.newDesign.slg} {
    width: 2069px;
    height: 2069px;
  }
  ${({ theme }) => theme.adaptive.newDesign.md} {
    width: 1534px;
    height: 1534px;
  }
  ${({ theme }) => theme.adaptive.newDesign.sm} {
    background: url('${circlesSm}') center no-repeat;
    background-size: cover;
    width: 1343px;
    height: 1343px;
    top: 42%;
  }
  ${({ theme }) => theme.adaptive.newDesign.xs} {
    width: 1048px;
    height: 1048px;
  }
`

const EllipseBlur = styled.div`
  position: absolute;
  top: 0;
  left: -85%;
  width: 1801px;
  height: 1740px;
  background: url('${ellipse}') center no-repeat;
  background-size: cover;
  filter: blur(220px);
  transform: translate3d(0, 0, 0);
  ${({ theme }) => theme.adaptive.newDesign.xl} {
    width: 1576px;
    height: 1523px;
    filter: blur(193px);
  }
  ${({ theme }) => theme.adaptive.newDesign.lg} {
    width: 1351px;
    height: 1305px;
    filter: blur(165px);
  }
  ${({ theme }) => theme.adaptive.newDesign.slg} {
    width: 1164px;
    height: 1125px;
    filter: blur(142px);
  }
  ${({ theme }) => theme.adaptive.newDesign.md} {
    width: 864px;
    height: 835px;
    filter: blur(105px);
  }
  ${({ theme }) => theme.adaptive.newDesign.sm} {
    display: none;
  }
`

const EllipseBlur2 = styled.div`
  position: absolute;
  top: 50%;

  left: 90%;
  width: 1801px;
  height: 1740px;
  background: url('${ellipse}') center no-repeat;
  background-size: cover;
  filter: blur(220px);
  transform: translate3d(0, -50%, 0);
  ${({ theme }) => theme.adaptive.newDesign.xl} {
    width: 1576px;
    height: 1523px;
    filter: blur(193px);
  }
  ${({ theme }) => theme.adaptive.newDesign.lg} {
    width: 1351px;
    height: 1305px;
    filter: blur(165px);
  }
  ${({ theme }) => theme.adaptive.newDesign.slg} {
    width: 1164px;
    height: 1125px;
    filter: blur(142px);
  }
  ${({ theme }) => theme.adaptive.newDesign.md} {
    width: 864px;
    height: 835px;
    filter: blur(105px);
  }
  ${({ theme }) => theme.adaptive.newDesign.sm} {
    display: none;
  }
`
