import React from 'react'
import styled, { css } from 'styled-components'
import { TitleH2 } from '../styles'
import LMBg from 'img/new/tocenomics/LM-bg.svg'
import LMBgSmm from 'img/new/tocenomics/LM-bg-smm.svg'
import LMImg from 'img/new/tocenomics/LM-img.svg'
import TXBg from 'img/new/tocenomics/TX-bg.svg'
import TXImg from 'img/new/tocenomics/TX-img.svg'
import DistributionImg from 'img/new/tocenomics/Destribution-img.svg'

const Tokenomics: React.FunctionComponent = () => {
  return (
    <Root>
      <TitleWrapper>
        <TitleH2>Tokenomics</TitleH2>
      </TitleWrapper>
      <Content>
        <Blur />
        <SecondBlur />
        <LM>
          <LMTitle>Of all OCX liquidity incentives</LMTitle>
          <LMTextCont>
            <LMText>LPs receive continuous LM rewards in OCX</LMText>
            <TextDisc />
          </LMTextCont>
          <LMTextCont>
            <LMText>
              Every liquidity pool has a weight defining the rewards allocation
            </LMText>
            <TextDisc color={'#8B8B8B'} />
          </LMTextCont>
          <LMTextCont>
            <LMText>Every weight is configurable by the DAO</LMText>
            <TextDisc color={'#484848'} />
          </LMTextCont>
          <LMCardImg />
          <LMBgText>Utility</LMBgText>
        </LM>
        <Distribution>
          <TopLeftText>30% OCC/ADA staking rewards</TopLeftText>
          <TopRightText>15% Liquidity Mining Campaign</TopRightText>
          <BottomLeftText>20% Team</BottomLeftText>
          <BottomRightText>35% Ecosystem development</BottomRightText>
        </Distribution>
        <TX>
          <TXTitle>
            Of all TX fees <span>(30 bps taker)</span>
          </TXTitle>
          <TXTextCont>
            <LMText>Liquidity providers get 25 bps</LMText>
            <TextDisc color={'#EE9A4E'} />
          </TXTextCont>
          <TXTextCont>
            <LMText>OCX Stakers get 5 bps through buybacks</LMText>
            <TextDisc color={'rgba(248, 159, 95, 0.75)'} />
          </TXTextCont>
          <TXTextCont>
            <LMText>Configurable by the DAO</LMText>
            <TextDisc color={'rgba(248, 159, 95, 0.5)'} />
          </TXTextCont>
          <TXTextCont>
            <LMText>Soon to go cross-chain</LMText>
            <TextDisc color={'rgba(248, 159, 95, 0.25)'} />
          </TXTextCont>
          <TXCardImg />
          <TXBgText>Utility</TXBgText>
        </TX>
      </Content>
    </Root>
  )
}

export default Tokenomics

const Root = styled.div`
  /* margin-top: 1450px; */
  position: relative;
  z-index: 3;
  padding-top: 170px;

  ${({ theme }) => theme.adaptive.newDesign.xl} {
    padding-top: 148px;
  }

  ${({ theme }) => theme.adaptive.newDesign.lg} {
    padding-top: 128px;
  }

  ${({ theme }) => theme.adaptive.newDesign.slg} {
    padding-top: 110px;
  }

  ${({ theme }) => theme.adaptive.newDesign.md} {
    padding-top: 80px;
  }

  ${({ theme }) => theme.adaptive.newDesign.sm} {
    padding-top: 120px;
  }
  ${({ theme }) => theme.adaptive.newDesign.smm} {
    padding-top: 120px;
  }

  ${({ theme }) => theme.adaptive.newDesign.xs} {
    padding-top: 94px;
  }
`

const TitleWrapper = styled.div`
  position: relative;
  margin-bottom: 120px;

  ${({ theme }) => theme.adaptive.newDesign.xl} {
    margin-bottom: 110px;
  }

  ${({ theme }) => theme.adaptive.newDesign.lg} {
    margin-bottom: 86px;
  }

  ${({ theme }) => theme.adaptive.newDesign.slg} {
    margin-bottom: 74px;
  }

  ${({ theme }) => theme.adaptive.newDesign.md} {
    margin-bottom: 56px;
  }

  ${({ theme }) => theme.adaptive.newDesign.sm} {
    margin-bottom: 0;
  }
`

const Content = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${({ theme }) => theme.adaptive.newDesign.sm} {
    padding-top: 562px;
    flex-wrap: wrap;
  }

  ${({ theme }) => theme.adaptive.newDesign.smm} {
    padding-top: 408px;
    width: 331px;
    margin: 0 auto;
  }

  ${({ theme }) => theme.adaptive.newDesign.xs} {
    padding-top: 318px;
    width: 256px;
    margin: 0 auto;
  }
`

const CardTitle = css`
  font-weight: 200;
  font-size: 35px;
  line-height: 140%;
  color: #ffffff;
  margin-bottom: 33px;

  ${({ theme }) => theme.adaptive.newDesign.xl} {
    font-size: 30px;
    margin-bottom: 28px;
  }

  ${({ theme }) => theme.adaptive.newDesign.lg} {
    font-size: 26px;
    margin-bottom: 24px;
  }

  ${({ theme }) => theme.adaptive.newDesign.slg} {
    font-size: 22px;
    margin-bottom: 21px;
  }

  ${({ theme }) => theme.adaptive.newDesign.md} {
    font-size: 16px;
    margin-bottom: 16px;
  }

  ${({ theme }) => theme.adaptive.newDesign.sm} {
    font-size: 18px;
    margin-bottom: 18px;
  }

  ${({ theme }) => theme.adaptive.newDesign.smm} {
    font-size: 24px;
    margin-bottom: 24px;
  }

  ${({ theme }) => theme.adaptive.newDesign.xs} {
    font-size: 18px;
    margin-bottom: 18px;
  }
`

const TextCss = css`
  font-weight: 300;
  font-size: 22px;
  line-height: 140%;
  color: #ffffff;
  opacity: 0.5;

  ${({ theme }) => theme.adaptive.newDesign.xl} {
    font-size: 20px;
  }

  ${({ theme }) => theme.adaptive.newDesign.lg} {
    font-size: 18px;
  }

  ${({ theme }) => theme.adaptive.newDesign.slg} {
    font-size: 15px;
  }

  ${({ theme }) => theme.adaptive.newDesign.md} {
    font-size: 12px;
  }

  ${({ theme }) => theme.adaptive.newDesign.sm} {
    font-size: 14px;
  }

  ${({ theme }) => theme.adaptive.newDesign.smm} {
    font-size: 19px;
  }

  ${({ theme }) => theme.adaptive.newDesign.xs} {
    font-size: 14px;
  }
`

const CardImg = css`
  position: absolute;
  width: 220px;
  height: 220px;

  ${({ theme }) => theme.adaptive.newDesign.xl} {
    width: 174px;
    height: 174px;
  }

  ${({ theme }) => theme.adaptive.newDesign.lg} {
    width: 151px;
    height: 151px;
  }

  ${({ theme }) => theme.adaptive.newDesign.slg} {
    width: 130px;
    height: 130px;
  }

  ${({ theme }) => theme.adaptive.newDesign.md} {
    width: 99px;
    height: 99px;
  }

  ${({ theme }) => theme.adaptive.newDesign.sm} {
    width: 113px;
    height: 113px;
  }

  ${({ theme }) => theme.adaptive.newDesign.smm} {
    width: 150px;
    height: 150px;
  }

  ${({ theme }) => theme.adaptive.newDesign.xs} {
    width: 113px;
    height: 113px;
  }
`

const TextCont = css`
  position: relative;
  padding-left: 27px;
  margin-bottom: 28px;

  ${({ theme }) => theme.adaptive.newDesign.xl} {
    padding-left: 24px;
    margin-bottom: 22px;
  }

  ${({ theme }) => theme.adaptive.newDesign.lg} {
    padding-left: 21px;
    margin-bottom: 19px;
  }

  ${({ theme }) => theme.adaptive.newDesign.slg} {
    padding-left: 18px;
    margin-bottom: 16px;
  }

  ${({ theme }) => theme.adaptive.newDesign.md} {
    padding-left: 13px;
    margin-bottom: 12px;
  }

  ${({ theme }) => theme.adaptive.newDesign.sm} {
    padding-left: 15px;
    margin-bottom: 12px;
  }

  ${({ theme }) => theme.adaptive.newDesign.smm} {
    padding-left: 20px;
    margin-bottom: 19px;
  }

  ${({ theme }) => theme.adaptive.newDesign.xs} {
    padding-left: 15px;
    margin-bottom: 12px;
  }

  &:last-child {
    margin-bottom: 0;
  }
`

const CardBgText = css`
  position: absolute;
  font-weight: 500;
  font-size: 22px;
  line-height: 140%;
  color: #ffffff;
  opacity: 0.1;

  ${({ theme }) => theme.adaptive.newDesign.xl} {
    font-size: 19px;
  }

  ${({ theme }) => theme.adaptive.newDesign.lg} {
    font-size: 16px;
  }

  ${({ theme }) => theme.adaptive.newDesign.slg} {
    font-size: 14px;
  }

  ${({ theme }) => theme.adaptive.newDesign.md} {
    font-size: 11px;
  }

  ${({ theme }) => theme.adaptive.newDesign.sm} {
    font-size: 13px;
  }

  ${({ theme }) => theme.adaptive.newDesign.smm} {
    font-size: 18px;
  }

  ${({ theme }) => theme.adaptive.newDesign.xs} {
    font-size: 13px;
  }
`

const TextDisc = styled.div`
  position: absolute;
  top: 8px;
  left: 0px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: ${({ color }) => (color ? color : '#F0F0F0')};

  ${({ theme }) => theme.adaptive.newDesign.xl} {
    top: 8px;
    width: 11px;
    height: 11px;
  }

  ${({ theme }) => theme.adaptive.newDesign.lg} {
    top: 8px;
    width: 10px;
    height: 10px;
  }

  ${({ theme }) => theme.adaptive.newDesign.slg} {
    top: 6px;
    width: 8px;
    height: 8px;
  }

  ${({ theme }) => theme.adaptive.newDesign.md} {
    top: 5px;
    width: 6px;
    height: 6px;
  }

  ${({ theme }) => theme.adaptive.newDesign.sm} {
    top: 4px;
    width: 7px;
    height: 7px;
  }

  ${({ theme }) => theme.adaptive.newDesign.smm} {
    top: 8px;
    width: 10px;
    height: 10px;
  }

  ${({ theme }) => theme.adaptive.newDesign.xs} {
    top: 4px;
    width: 7px;
    height: 7px;
  }
`

const LM = styled.div`
  position: relative;
  padding: 27px 38px;
  width: 467px;
  height: 521px;
  background: url('${LMBg}') center no-repeat;
  background-size: cover;

  ${({ theme }) => theme.adaptive.newDesign.xl} {
    padding: 24px 33px;
    width: 407px;
    height: 454px;
  }

  ${({ theme }) => theme.adaptive.newDesign.lg} {
    padding: 20px 28px;
    width: 349px;
    height: 390px;
  }

  ${({ theme }) => theme.adaptive.newDesign.slg} {
    padding: 18px 25px;
    width: 302px;
    height: 337px;
  }

  ${({ theme }) => theme.adaptive.newDesign.md} {
    padding: 13px 18px;
    width: 225px;
    height: 251px;
  }

  ${({ theme }) => theme.adaptive.newDesign.sm} {
    padding: 15px 21px;
    width: 259px;
    height: 289px;
    order: 2;
  }

  ${({ theme }) => theme.adaptive.newDesign.smm} {
    padding: 20px 28px;
    width: 331px;
    height: 433px;
    margin-bottom: 45px;
    background: url('${LMBgSmm}') center no-repeat;
    background-size: cover;
  }

  ${({ theme }) => theme.adaptive.newDesign.xs} {
    padding: 15px 21px;
    width: 255px;
    height: 333px;
    margin-bottom: 34px;
  }
`

const LMTitle = styled.div`
  ${CardTitle};
  width: 207px;

  ${({ theme }) => theme.adaptive.newDesign.xl} {
    width: 210px;
  }

  ${({ theme }) => theme.adaptive.newDesign.lg} {
    width: 182px;
  }

  ${({ theme }) => theme.adaptive.newDesign.slg} {
    width: 156px;
  }

  ${({ theme }) => theme.adaptive.newDesign.md} {
    width: 112px;
  }

  ${({ theme }) => theme.adaptive.newDesign.sm} {
    width: 126px;
  }

  ${({ theme }) => theme.adaptive.newDesign.smm} {
    width: 168px;
  }

  ${({ theme }) => theme.adaptive.newDesign.xs} {
    width: 127px;
  }
`

const LMTextCont = styled.div`
  ${TextCont};
  max-width: 235px;

  ${({ theme }) => theme.adaptive.newDesign.xl} {
    max-width: 150px;
  }

  ${({ theme }) => theme.adaptive.newDesign.lg} {
    max-width: 144px;
  }

  ${({ theme }) => theme.adaptive.newDesign.slg} {
    max-width: 112px;
  }

  ${({ theme }) => theme.adaptive.newDesign.md} {
    max-width: 87px;
  }

  ${({ theme }) => theme.adaptive.newDesign.sm} {
    max-width: 103px;
  }

  ${({ theme }) => theme.adaptive.newDesign.smm} {
    max-width: 136px;

    &:nth-child(2) {
      max-width: 152px;
    }
  }

  ${({ theme }) => theme.adaptive.newDesign.xs} {
    max-width: 94px;

    &:nth-child(2) {
      max-width: 130px;
    }
  }

  &:nth-child(3) {
    max-width: 270px;

    ${({ theme }) => theme.adaptive.newDesign.xl} {
      max-width: 244px;
    }

    ${({ theme }) => theme.adaptive.newDesign.lg} {
      max-width: 218px;
    }

    ${({ theme }) => theme.adaptive.newDesign.slg} {
      max-width: 181px;
    }

    ${({ theme }) => theme.adaptive.newDesign.md} {
      max-width: 150px;
    }

    ${({ theme }) => theme.adaptive.newDesign.sm} {
      max-width: 176px;
    }

    ${({ theme }) => theme.adaptive.newDesign.smm} {
      max-width: 255px;
    }

    ${({ theme }) => theme.adaptive.newDesign.xs} {
      max-width: 176px;
    }
  }

  &:nth-child(4) {
    max-width: 270px;

    ${({ theme }) => theme.adaptive.newDesign.xl} {
      max-width: 218px;
    }

    ${({ theme }) => theme.adaptive.newDesign.lg} {
      max-width: 195px;
    }

    ${({ theme }) => theme.adaptive.newDesign.slg} {
      max-width: 163px;
    }

    ${({ theme }) => theme.adaptive.newDesign.md} {
      max-width: 130px;
    }

    ${({ theme }) => theme.adaptive.newDesign.sm} {
      max-width: 176px;
    }

    ${({ theme }) => theme.adaptive.newDesign.smm} {
      max-width: 255px;
    }

    ${({ theme }) => theme.adaptive.newDesign.xs} {
      max-width: 176px;
    }
  }
`

const LMText = styled.div`
  ${TextCss};
`

const LMCardImg = styled.div`
  ${CardImg};
  top: -10px;
  right: -10px;
  background: url('${LMImg}') center no-repeat;
  background-size: cover;

  ${({ theme }) => theme.adaptive.newDesign.xl} {
    top: 0;
    right: 0;
  }
`

const LMBgText = styled.div`
  ${CardBgText};
  top: 225px;
  right: -14px;
  transform: rotate(90deg);

  ${({ theme }) => theme.adaptive.newDesign.xl} {
    top: 200px;
    right: -12px;
  }

  ${({ theme }) => theme.adaptive.newDesign.lg} {
    top: 170px;
    right: -11px;
  }

  ${({ theme }) => theme.adaptive.newDesign.slg} {
    top: 150px;
    right: -10px;
  }

  ${({ theme }) => theme.adaptive.newDesign.md} {
    top: 110px;
    right: -9px;
  }

  ${({ theme }) => theme.adaptive.newDesign.sm} {
    top: 126px;
    right: -10px;
  }

  ${({ theme }) => theme.adaptive.newDesign.smm} {
    top: 185px;
    right: -11px;
  }

  ${({ theme }) => theme.adaptive.newDesign.xs} {
    top: 140px;
    right: -10px;
  }
`

const TX = styled.div`
  position: relative;
  padding: 27px 39px;
  width: 415px;
  height: 521px;
  background: url('${TXBg}') center no-repeat;
  background-size: cover;

  ${({ theme }) => theme.adaptive.newDesign.xl} {
    padding: 22px 26px 22px 34px;
    width: 361px;
    height: 453px;
  }

  ${({ theme }) => theme.adaptive.newDesign.lg} {
    padding: 20px 14px 20px 29px;
    width: 310px;
    height: 390px;
  }

  ${({ theme }) => theme.adaptive.newDesign.slg} {
    padding: 17px 9px 17px 25px;
    width: 268px;
    height: 337px;
  }

  ${({ theme }) => theme.adaptive.newDesign.md} {
    padding: 12px 5px 12px 19px;
    width: 200px;
    height: 251px;
  }

  ${({ theme }) => theme.adaptive.newDesign.sm} {
    padding: 14px 5px 14px 20px;
    width: 230px;
    height: 289px;
    order: 3;
  }

  ${({ theme }) => theme.adaptive.newDesign.smm} {
    padding: 20px 9px 20px 28px;
    width: 321px;
    height: 402px;
  }

  ${({ theme }) => theme.adaptive.newDesign.xs} {
    padding: 14px 5px 14px 20px;
    width: 230px;
    height: 289px;
  }

  span {
    font-weight: 500;
    font-size: 19px;
    line-height: 140%;
    color: #ffffff;
    opacity: 0.3;

    ${({ theme }) => theme.adaptive.newDesign.xl} {
      font-size: 16px;
    }

    ${({ theme }) => theme.adaptive.newDesign.lg} {
      font-size: 14px;
    }

    ${({ theme }) => theme.adaptive.newDesign.slg} {
      font-size: 13px;
    }

    ${({ theme }) => theme.adaptive.newDesign.md} {
      font-size: 10px;
    }

    ${({ theme }) => theme.adaptive.newDesign.sm} {
      font-size: 12px;
    }

    ${({ theme }) => theme.adaptive.newDesign.smm} {
      font-size: 16px;
    }

    ${({ theme }) => theme.adaptive.newDesign.xs} {
      font-size: 12px;
    }
  }
`

const TXTitle = styled.div`
  position: relative;
  ${CardTitle};
  width: 150px;

  ${({ theme }) => theme.adaptive.newDesign.xl} {
    width: 130px;
  }

  ${({ theme }) => theme.adaptive.newDesign.lg} {
    width: 116px;
  }

  ${({ theme }) => theme.adaptive.newDesign.slg} {
    width: 100px;
  }

  ${({ theme }) => theme.adaptive.newDesign.md} {
    width: 59px;
  }

  ${({ theme }) => theme.adaptive.newDesign.sm} {
    width: 68px;
  }

  ${({ theme }) => theme.adaptive.newDesign.smm} {
    width: 94px;
  }

  ${({ theme }) => theme.adaptive.newDesign.xs} {
    width: 68px;
  }

  span {
    display: block;
    position: absolute;
    bottom: 6px;
    right: -34px;

    ${({ theme }) => theme.adaptive.newDesign.xl} {
      bottom: 6px;
      right: -27px;
    }

    ${({ theme }) => theme.adaptive.newDesign.lg} {
      bottom: 4px;
      right: -21px;
    }

    ${({ theme }) => theme.adaptive.newDesign.slg} {
      bottom: 4px;
      right: -21px;
    }

    ${({ theme }) => theme.adaptive.newDesign.md} {
      bottom: 2px;
      right: -31px;
    }

    ${({ theme }) => theme.adaptive.newDesign.sm} {
      bottom: 2px;
      right: -38px;
    }

    ${({ theme }) => theme.adaptive.newDesign.smm} {
      bottom: 3px;
      right: -48px;
    }

    ${({ theme }) => theme.adaptive.newDesign.xs} {
      bottom: 2px;
      right: -38px;
    }
  }
`

const TXTextCont = styled.div`
  ${TextCont};
  max-width: 325px;

  ${({ theme }) => theme.adaptive.newDesign.xl} {
    max-width: 295px;
  }

  ${({ theme }) => theme.adaptive.newDesign.lg} {
    max-width: 267px;
  }

  ${({ theme }) => theme.adaptive.newDesign.slg} {
    max-width: 235px;
  }

  ${({ theme }) => theme.adaptive.newDesign.md} {
    max-width: 176px;
  }

  ${({ theme }) => theme.adaptive.newDesign.sm} {
    max-width: 205px;
  }

  ${({ theme }) => theme.adaptive.newDesign.smm} {
    max-width: 283px;
  }

  ${({ theme }) => theme.adaptive.newDesign.xs} {
    max-width: 205px;
  }

  &:nth-child(2) {
    max-width: 187px;

    ${({ theme }) => theme.adaptive.newDesign.xl} {
      max-width: 135px;
    }

    ${({ theme }) => theme.adaptive.newDesign.lg} {
      max-width: 132px;
    }

    ${({ theme }) => theme.adaptive.newDesign.slg} {
      max-width: 112px;
    }

    ${({ theme }) => theme.adaptive.newDesign.md} {
      max-width: 80px;
    }

    ${({ theme }) => theme.adaptive.newDesign.sm} {
      max-width: 99px;
    }

    ${({ theme }) => theme.adaptive.newDesign.smm} {
      max-width: 136px;
    }

    ${({ theme }) => theme.adaptive.newDesign.xs} {
      max-width: 102px;
    }
  }
`

const TXBgText = styled.div`
  ${CardBgText};
  bottom: 0;
  right: 30px;

  ${({ theme }) => theme.adaptive.newDesign.xl} {
    right: 23px;
  }

  ${({ theme }) => theme.adaptive.newDesign.lg} {
    right: 25px;
  }

  ${({ theme }) => theme.adaptive.newDesign.slg} {
    right: 21px;
  }

  ${({ theme }) => theme.adaptive.newDesign.md} {
    right: 15px;
  }

  ${({ theme }) => theme.adaptive.newDesign.sm} {
    right: 18px;
  }

  ${({ theme }) => theme.adaptive.newDesign.smm} {
    right: 25px;
  }

  ${({ theme }) => theme.adaptive.newDesign.xs} {
    right: 18px;
  }
`

const TXCardImg = styled.div`
  ${CardImg};
  top: -10px;
  right: -10px;
  background: url('${TXImg}') center no-repeat;
  background-size: cover;

  ${({ theme }) => theme.adaptive.newDesign.xl} {
    top: 0;
    right: 0;
  }
`

const Distribution = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 877px;
  height: 876px;
  background: url('${DistributionImg}') center no-repeat;
  background-size: cover;

  ${({ theme }) => theme.adaptive.newDesign.xl} {
    width: 763px;
    height: 764px;
  }

  ${({ theme }) => theme.adaptive.newDesign.lg} {
    width: 656px;
    height: 656px;
  }

  ${({ theme }) => theme.adaptive.newDesign.slg} {
    width: 568px;
    height: 567px;
  }

  ${({ theme }) => theme.adaptive.newDesign.md} {
    width: 424px;
    height: 423px;
  }

  ${({ theme }) => theme.adaptive.newDesign.sm} {
    width: 674px;
    height: 674px;
    top: -65px;
    transform: translateX(-50%);
  }

  ${({ theme }) => theme.adaptive.newDesign.smm} {
    width: 430px;
    height: 430px;
    top: -23px;
  }

  ${({ theme }) => theme.adaptive.newDesign.xs} {
    width: 330px;
    height: 330px;
    top: -7px;
  }
`

const DistributionTextCss = css`
  position: absolute;
  font-weight: 400;
  font-size: 22px;
  line-height: 125%;
  color: #ffffff;

  ${({ theme }) => theme.adaptive.newDesign.xl} {
    font-size: 19px;
  }

  ${({ theme }) => theme.adaptive.newDesign.lg} {
    font-size: 17px;
  }

  ${({ theme }) => theme.adaptive.newDesign.slg} {
    font-size: 15px;
  }

  ${({ theme }) => theme.adaptive.newDesign.md} {
    font-size: 11px;
  }

  ${({ theme }) => theme.adaptive.newDesign.sm} {
    font-size: 16px;
  }

  ${({ theme }) => theme.adaptive.newDesign.smm} {
    font-size: 11px;
  }
  ${({ theme }) => theme.adaptive.newDesign.xs} {
    font-size: 9px;
  }
`

const TopLeftText = styled.div`
  ${DistributionTextCss};
  top: 133px;
  left: 190px;
  max-width: 150px;

  ${({ theme }) => theme.adaptive.newDesign.xl} {
    top: 117px;
    left: 168px;
    max-width: 134px;
  }

  ${({ theme }) => theme.adaptive.newDesign.lg} {
    top: 98px;
    left: 142px;
    max-width: 117px;
  }

  ${({ theme }) => theme.adaptive.newDesign.slg} {
    top: 86px;
    left: 121px;
    max-width: 103px;
  }

  ${({ theme }) => theme.adaptive.newDesign.md} {
    top: 64px;
    left: 92px;
    max-width: 76px;
  }

  ${({ theme }) => theme.adaptive.newDesign.sm} {
    top: 103px;
    left: 148px;
    max-width: 111px;
  }

  ${({ theme }) => theme.adaptive.newDesign.smm} {
    top: 65px;
    left: 92px;
    max-width: 75px;
  }
  ${({ theme }) => theme.adaptive.newDesign.xs} {
    top: 50px;
    left: 70px;
  }
`

const TopRightText = styled.div`
  ${DistributionTextCss};
  top: 136px;
  right: 144px;
  max-width: 190px;

  ${({ theme }) => theme.adaptive.newDesign.xl} {
    top: 120px;
    right: 115px;
    max-width: 165px;
  }

  ${({ theme }) => theme.adaptive.newDesign.lg} {
    top: 101px;
    right: 97px;
    max-width: 145px;
  }

  ${({ theme }) => theme.adaptive.newDesign.slg} {
    top: 88px;
    right: 82px;
    max-width: 130px;
  }

  ${({ theme }) => theme.adaptive.newDesign.md} {
    top: 67px;
    right: 64px;
    max-width: 93px;
  }

  ${({ theme }) => theme.adaptive.newDesign.sm} {
    top: 106px;
    right: 107px;
    max-width: 138px;
  }

  ${({ theme }) => theme.adaptive.newDesign.smm} {
    top: 67px;
    right: 64px;
    max-width: 94px;
  }
  ${({ theme }) => theme.adaptive.newDesign.xs} {
    top: 50px;
    right: 32px;
  }
`

const BottomLeftText = styled.div`
  ${DistributionTextCss};
  bottom: 263px;
  left: 121px;

  ${({ theme }) => theme.adaptive.newDesign.xl} {
    bottom: 232px;
    left: 107px;
  }

  ${({ theme }) => theme.adaptive.newDesign.lg} {
    bottom: 199px;
    left: 90px;
  }

  ${({ theme }) => theme.adaptive.newDesign.slg} {
    bottom: 169px;
    left: 79px;
  }

  ${({ theme }) => theme.adaptive.newDesign.md} {
    bottom: 127px;
    left: 58px;
  }

  ${({ theme }) => theme.adaptive.newDesign.sm} {
    bottom: 201px;
    left: 89px;
  }

  ${({ theme }) => theme.adaptive.newDesign.smm} {
    bottom: 128px;
    left: 56px;
  }
  ${({ theme }) => theme.adaptive.newDesign.xs} {
    bottom: 98px;
    left: 45px;
  }
`

const BottomRightText = styled.div`
  ${DistributionTextCss};
  bottom: 195px;
  right: 90px;
  max-width: 150px;

  ${({ theme }) => theme.adaptive.newDesign.xl} {
    bottom: 173px;
    right: 78px;
    max-width: 130px;
  }

  ${({ theme }) => theme.adaptive.newDesign.lg} {
    bottom: 148px;
    right: 61px;
    max-width: 123px;
  }

  ${({ theme }) => theme.adaptive.newDesign.slg} {
    bottom: 125px;
    right: 54px;
    max-width: 106px;
  }

  ${({ theme }) => theme.adaptive.newDesign.md} {
    bottom: 94px;
    right: 39px;
    max-width: 79px;
  }

  ${({ theme }) => theme.adaptive.newDesign.sm} {
    bottom: 147px;
    right: 64px;
    max-width: 113px;
  }

  ${({ theme }) => theme.adaptive.newDesign.smm} {
    bottom: 94px;
    right: 43px;
    max-width: 76px;
  }
  ${({ theme }) => theme.adaptive.newDesign.xs} {
    bottom: 71px;
    right: 17px;
  }
`

const Blur = styled.div`
  position: absolute;
  top: -178px;
  left: 79px;
  width: 443px;
  height: 443px;
  background: #6ca7ff;
  opacity: 0.5;
  filter: blur(300px);
  transform: translate3d(0, 0, 0);
  ${({ theme }) => theme.adaptive.newDesign.xl} {
    top: -160px;
    left: 65px;
    width: 388px;
    height: 388px;
    filter: blur(260px);
  }
  ${({ theme }) => theme.adaptive.newDesign.lg} {
    top: -137px;
    left: 55px;
    width: 332px;
    height: 332px;
    filter: blur(225px);
  }
  ${({ theme }) => theme.adaptive.newDesign.slg} {
    top: -118px;
    left: 49px;
    width: 286px;
    height: 286px;
    filter: blur(173px);
  }
  ${({ theme }) => theme.adaptive.newDesign.md} {
    top: -88px;
    left: 36px;
    width: 212px;
    height: 212px;
    filter: blur(140px);
  }
  ${({ theme }) => theme.adaptive.newDesign.sm} {
    top: 413px;
    left: unset;
    right: -190px;
    width: 273px;
    height: 273px;
  }
  ${({ theme }) => theme.adaptive.newDesign.xs} {
    top: 322px;
    right: -153px;
    width: 166px;
    height: 166px;
    filter: blur(120px);
  }
`

const SecondBlur = styled.div`
  display: none;
  position: absolute;
  background: #6ca7ff;
  opacity: 0.5;
  transform: translate3d(0, 0, 0);
  ${({ theme }) => theme.adaptive.newDesign.sm} {
    display: block;
    top: 920px;
    left: -206px;
    width: 393px;
    height: 393px;
    filter: blur(140px);
  }
  ${({ theme }) => theme.adaptive.newDesign.xs} {
    top: 718px;
    left: -157px;
    width: 307px;
    height: 307px;
    filter: blur(120px);
  }
`
