import React from 'react'
import styled from 'styled-components'
import bgBot from 'img/new/header/bg-bot.svg'
import SocialBgSm from 'img/new/header/border-sm.svg'
import { Link } from 'react-router-dom'

interface Menuprops {
  active: boolean
  onClose: () => void
  list: { name: string; link: string; isNewTab?: boolean }[]
}

const HeaderMenu: React.FunctionComponent<Menuprops> = (props) => {
  return (
    <>
      <HeaderBackground onClick={props.onClose} isActive={props.active} />
      <HeaderMenuRoot isActive={props.active}>
        <MenuContent>
          <Links>
            {props.list.map((nav, idx) => {
              return (
                <Link
                  to={nav.isNewTab ? { pathname: nav.link } : nav.link}
                  target={nav.isNewTab ? '_blank' : ''}
                >
                  {nav.name}
                </Link>
              )
            })}
          </Links>
          <BottomBlock>
            {/*<Docs>*/}
            {/*  <DocWrap href={'/docs/occam_deck.pdf'}>*/}
            {/*    <DocImg src={deckImg} />*/}
            {/*    <DocName>Deck</DocName>*/}
            {/*  </DocWrap>*/}
            {/*  <DocWrap href={'/docs/OccamX_Whitepaper.pdf'}>*/}
            {/*    <DocImg src={paperImg}/>*/}
            {/*    <DocName>White Paper</DocName>*/}
            {/*  </DocWrap>*/}
            {/*  <DocWrap href={'/docs/occamrazer_wp_v1.pdf'}>*/}
            {/*    <DocImg src={occamXImg} />*/}
            {/*    <DocName>OccamX</DocName>*/}
            {/*  </DocWrap>*/}
            {/*</Docs>*/}
            {/*<SocialsWrap>*/}
            {/*  <SocialsCont>*/}
            {/*    <Socials wrap={true} />*/}
            {/*  </SocialsCont>*/}
            {/*  <SocialText>Be a part of our community</SocialText>*/}
            {/*</SocialsWrap>*/}
          </BottomBlock>
          <Bg>
            <BgRow></BgRow>
            <BgRow>
              <BgMid />
            </BgRow>
            <BgRow>
              <BgBot />
            </BgRow>
          </Bg>
        </MenuContent>
      </HeaderMenuRoot>
    </>
  )
}

export default HeaderMenu

const HeaderBackground = styled.div<{ isActive: boolean }>`
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  transition: 0.5s ease-in-out;
  opacity: ${({ isActive }) => (isActive ? '1' : '0')};
  z-index: ${({ isActive }) => (isActive ? '6' : '0')};
  pointer-events: ${({ isActive }) => (isActive ? 'auto' : 'none')};
  backdrop-filter: blur(5px);
`

const HeaderMenuRoot = styled.div<{ isActive: boolean }>`
  position: fixed;
  height: 100%;
  top: 0;
  transition: 0.5s ease-in-out;
  overflow: hidden;
  z-index: 10;
  left: ${({ isActive }) => (isActive ? '0' : '100%')};
  right: unset;
  width: 708px;
  @media screen and (min-width: 708px) {
    left: unset;
    right: ${({ isActive }) => (isActive ? '0' : '-734px')};
  }
`

const MenuContent = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 80px 183px 78px 80px;

  ${({ theme }) => theme.adaptive.newDesign.xs} {
    padding: 80px 183px 30px 20px;
  }
`

const Bg = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  z-index: -1;
`

const BgRow = styled.div`
  display: flex;
  justify-content: flex-end;

  &:nth-child(2) {
    flex: 1;
  }
`

const BgBot = styled.div`
  width: 708px;
  height: 306px;
  background: url('${bgBot}') center no-repeat;

  ${({ theme }) => theme.adaptive.newDesign.xs} {
    display: none;
  }
`

const BgMid = styled.div`
  width: 708px;
  height: 100%;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.65), rgba(0, 0, 0, 0.65)),
    linear-gradient(90deg, #505d6d 0%, #31334a 47.4%, #151821 100%);
  opacity: 0.95;
`

const Links = styled.div`
  a {
    position: relative;
    display: block;
    font-weight: 300;
    text-transform: uppercase;
    color: #939ba9;
    transition: 0.4s;
    width: 180px;
    font-size: 17px;
    line-height: 22px;
    margin-bottom: 8px;

    @media screen and (max-height: 470px) {
      margin-bottom: 6px;
    }

    &:last-child {
      margin: 0;
    }

    &:hover {
      color: #ffffff;
    }
  }
`

const BottomBlock = styled.div``

const SocialsWrap = styled.div`
  margin-top: 30px;
  display: flex;
  flex-wrap: wrap;
  padding: 23px 20px;
  margin-left: 30px;
  width: 311px;
  height: 119px;
  background: url('${SocialBgSm}') center no-repeat;
  background-size: cover;

  ${({ theme }) => theme.adaptive.newDesign.xs} {
    margin-left: 0;
    margin-top: 20px;
  }

  @media screen and (max-height: 450px) {
    display: none;
  }
`

const SocialText = styled.div`
  font-weight: 200;
  line-height: 120%;
  letter-spacing: -0.02em;
  color: #939ba9;
  opacity: 0.7;

  font-size: 24px;
  width: 100%;
  margin: 22px 0 0 0;
  text-align: right;
`

const SocialsCont = styled.div`
  width: 100%;

  a {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: 27px;
    height: 26px;
    margin: 0 21px 0 0;

    svg {
      max-width: 27px;
      max-height: 26px;
    }
  }
`
