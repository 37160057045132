export default {
  adaptive: {
    lg: '@media screen and (max-width: 1330px)',
    sm: '@media screen and (max-width: 780px)',
    xsm: '@media screen and (max-width: 540px)',
    partners: {
      lg: '@media screen and (max-width: 1330px)',
    },
    index: {
      xl: '@media screen and (max-width: 1850px)',
      lg: '@media screen and (max-width: 1650px)',
      md: '@media screen and (max-width: 1300px)',
      smd: '@media screen and (max-width: 1060px)',
      sm: '@media screen and (max-width: 830px)',
      smm: '@media screen and (max-width: 380px)',
      preview: {
        lg: '@media screen and (max-width: 1060px)',
        md: '@media screen and (max-width: 920px)',
      },
      main: {
        xl: '@media screen and (max-width: 1850px)',
        lg: '@media screen and (max-width: 1650px)',
        slg: '@media screen and (max-width: 1400px)',
        md: '@media screen and (max-width: 1200px)',
      },
    },
    forms: {
      lg: '@media screen and (max-width: 1330px), screen and (max-height: 820px)',
      md: '@media screen and (max-width: 1000px), screen and (max-height: 740px)',
      sm: '@media screen and (max-width: 780px)',
    },
    headerMenu: {
      xl: '@media screen and (max-width: 1680px)',
      lg: '@media screen and (max-width: 1440px), screen and (max-height: 820px)',
      slg: '@media screen and (max-width: 1240px), screen and (max-height: 780px)',
      md: '@media screen and (max-width: 920px), screen and (max-height: 580px)',
      sm: '@media screen and (max-width: 620px)',
      smm: '@media screen and (max-width: 360px), screen and (max-width: 620px) and (max-height: 749px)',
    },
    ecosystem: {
      lg: '@media screen and (max-width: 1650px)',
      md: '@media screen and (max-width: 1000px)',
      sm: '@media screen and (max-width: 780px)',
      smm: '@media screen and (max-width: 340px)',
    },
    team: {
      lg: '@media screen and (max-width: 1440px)',
    },
    anim: {
      lg: '@media screen and (max-width: 1330px)',
      md: '@media screen and (max-width: 1000px)',
      sm: '@media screen and (max-width: 780px)',
      smm: '@media screen and (max-width: 350px)',
    },
    swap: {
      xl: '@media screen and (max-width: 1800px)',
      lg: '@media screen and (max-width: 1350px)',
      md: '@media screen and (max-width: 900px)',
      smm: '@media screen and (max-width: 350px)',
    },
    newIndex: {
      xl: '@media screen and (max-width: 1750px)',
      lg: '@media screen and (max-width: 1500px)',
      md: '@media screen and (max-width: 1300px)',
      smd: '@media screen and (max-width: 1100px)',
      sm: '@media screen and (max-width: 800px)',
      smm: '@media screen and (max-width: 420px)',
    },
    newFundraising: {
      xl: '@media screen and (max-width: 1850px)',
      lg: '@media screen and (max-width: 1650px)',
      md: '@media screen and (max-width: 1400px)',
      smd: '@media screen and (max-width: 1200px)',
      sm: '@media screen and (max-width: 880px)',
      smm: '@media screen and (max-width: 380px)',
    },
    olympus: {
      xl: '@media screen and (max-width: 1750px)',
      lg: '@media screen and (max-width: 1540px)',
      slg: '@media screen and (max-width: 1300px)',
      md: '@media screen and (max-width: 1100px)',
      smd: '@media screen and (max-width: 830px)',
      sm: '@media screen and (max-width: 620px)',
      ssm: '@media screen and (max-width: 374px)',
    },
    zeroTier: {
      xl: '@media screen and (max-width: 1800px)',
      lg: '@media screen and (max-width: 1540px)',
      slg: '@media screen and (max-width: 1300px)',
      md: '@media screen and (max-width: 1100px)',
      smd: '@media screen and (max-width: 830px)',
      sm: '@media screen and (max-width: 620px)',
      ssm: '@media screen and (max-width: 374px)',
    },
    olympusModal: {
      lg: '@media screen and (max-width: 1540px)',
      md: '@media screen and (max-width: 1100px)',
      sm: '@media screen and (max-width: 620px), screen and (max-height: 810px)',
      ssm: '@media screen and (max-width: 400px), screen and (max-height: 540px)',
    },
    dashboard: {
      xxl: '@media screen and (max-width: 1920px)',
      xl: '@media screen and (max-width: 1680px)',
      lg: '@media screen and (max-width: 1440px)',
      md: '@media screen and (max-width: 1280px)',
      sm: '@media screen and (max-width: 919px)',
    },
    occamx: {
      xl: '@media screen and (max-width: 1850px)',
      lg: '@media screen and (max-width: 1650px)',
      slg: '@media screen and (max-width: 1300px)',
      md: '@media screen and (max-width: 1160px)',
      smd: '@media screen and (max-width: 880px)',
      sm: '@media screen and (max-width: 600px)',
      smm: '@media screen and (max-width: 420px)',
    },
    newDesign: {
      xl: '@media screen and (max-width: 1950px)',
      lg: '@media screen and (max-width: 1660px)',
      slg: '@media screen and (max-width: 1420px)',
      md: '@media screen and (max-width: 1260px)',
      sm: '@media screen and (max-width: 860px)',
      smm: '@media screen and (max-width: 580px)',
      xs: '@media screen and (max-width: 370px)',
    },
  },
}

export const getSphereAdaptive = (windowWidth) => {
  if (windowWidth <= 830) return 'sm'
  else if (windowWidth <= 1200) return 'md'
  else if (windowWidth <= 1400) return 'slg'
  else if (windowWidth <= 1650) return 'lg'
  else if (windowWidth <= 1850) return 'xl'
  else return 'max'
}
