import React from 'react'

import styled, { css } from 'styled-components'

import leftBlockBg from '../../img/dashboard/bg-left-1920.svg'
import leftBlockBgScale from '../../img/dashboard/bg-left-scale.svg'
import rightBlockBg from '../../img/dashboard/bg-right-1920.svg'
import rightBlockBgScale from '../../img/dashboard/bg-right-scale.svg'
import rightBlockBg920 from '../../img/dashboard/bg-right-920.svg'
import youTubeIcon from '../../img/dashboard/youtube.svg'
import { ReactComponent as ExternalLinkIcon } from '../../img/common/external-link.svg'
import { ReactComponent as DaedalusIcon } from '../../img/dashboard/daedalus.svg'
import { ReactComponent as YoroiIcon } from '../../img/dashboard/yoroi.svg'

import CalculateReturn from './CalculateReturn'
import Delegators from './Delegators'
import TotalAdaStaked from './TotalADAStaked'
import OcxDistributed from './OCXDistributed'
import OcxLeftToDistribute from './OCXLeftToDistribute'
import useWindowSize from '../../helpers/utils/useWindowSize'
import Chart from './Chart'

export default function Content({
  ocxPerMinute,
  timeElapsedPercent,
  ocxAllocated,
  ocxLeft,
  delegators,
  totalADAStaked,
}) {
  const { width } = useWindowSize()
  const rightBlockIconSize =
    width >= 1680
      ? { w: 43, h: 34.1 }
      : width >= 1440
      ? { w: 36.74, h: 29.14 }
      : { w: 32.39, h: 25.68 }

  const isMobile = width < 920

  return (
    <Wrapper>
      <LeftBlock>
        <Bullets>
          <Bullet>
            <UL>
              <LI>
                Started on Sep 9, 2021{' '}
                <BulletSmallText>Epoch #290</BulletSmallText>
              </LI>
              <LI>
                Ending on Sep 9, 2022{' '}
                <BulletSmallText>Epoch #361</BulletSmallText>
              </LI>
              <LI>10m of OCX to be distributed to ADA delegators</LI>
              <LI>Maximum of 8 stake pools for delegation</LI>
              <LI>
                OCX will be distributed automatically to delegator address
              </LI>
              <LI>
                Distribution starts either shortly after 361 epoch or shortly
                after OccamX launch depending on what is earlier
              </LI>
              <LI>
                Tokens will be vested over 12 months starting from the date of
                the first distribution
              </LI>
            </UL>
          </Bullet>
          <MediumLinkContainer>
            <MediumLink
              href="https://medium.com/occam-finance/ocx-pocc-cardano-stake-pool-distribution-guide-d3d931eb9e4e"
              target="_blank"
              rel="noreferrer"
            >
              OccamX on Medium&nbsp;
              <sup>
                <ExternalLinkIcon fill="#394045" />
              </sup>
            </MediumLink>
          </MediumLinkContainer>
        </Bullets>
        <Panel>
          <Numbers>
            {isMobile && (
              <MobileChartWrapper>
                <StyledChart
                  speed={ocxPerMinute}
                  timePercent={timeElapsedPercent}
                />
              </MobileChartWrapper>
            )}
            <OcxDistributed ocx={ocxAllocated} percent={timeElapsedPercent} />
            <OCXLeftAndDelegators>
              <OcxLeftToDistribute ocx={ocxLeft} />
              <Delegators delegators={delegators} />
            </OCXLeftAndDelegators>
          </Numbers>
          <InteractivePanel>
            <CalculateReturn />
            {!isMobile && (
              <StyledChart
                speed={ocxPerMinute}
                timePercent={timeElapsedPercent}
              />
            )}
          </InteractivePanel>
        </Panel>
      </LeftBlock>
      <RightBlock>
        <TotalAdaStaked ada={totalADAStaked} />
        <RightBlockButtons>
          <RightBlockLink
            href="https://youtu.be/PAei7NFBAPU"
            target="_blank"
            rel="noreferrer"
          >
            How to delegate ADA to receive OCX
          </RightBlockLink>
          <a href="https://daedaluswallet.io" target="_blank" rel="noreferrer">
            <RightBlockButton borderColor="#82FF65">
              Delegate with Daedalus&ensp;
              <DaedalusIcon
                width={rightBlockIconSize.w}
                height={rightBlockIconSize.h}
              />
            </RightBlockButton>
          </a>
          <a href="https://yoroi-wallet.com/" target="_blank" rel="noreferrer">
            <RightBlockButton borderColor="#4061D2">
              Delegate with Yoroi&ensp;
              <YoroiIcon
                width={rightBlockIconSize.w}
                height={rightBlockIconSize.h}
              />
            </RightBlockButton>
          </a>
        </RightBlockButtons>
      </RightBlock>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 1800px;
  margin: 0 auto;

  padding-bottom: 115px;

  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;

  ${({ theme }) => theme.adaptive.dashboard.xxl} {
    width: 1580px;
    padding-bottom: 53px;
  }

  ${({ theme }) => theme.adaptive.dashboard.xl} {
    width: 1350px;
    padding-bottom: 144px;
  }

  ${({ theme }) => theme.adaptive.dashboard.lg} {
    width: 1190px;
    padding-bottom: 97px;
  }

  ${({ theme }) => theme.adaptive.dashboard.md} {
    width: 874px;
    padding-bottom: 86px;
  }

  ${({ theme }) => theme.adaptive.dashboard.sm} {
    width: 326px;
    padding-bottom: 140px;
  }
`

const LeftBlock = styled.div`
  width: 1300px;
  height: 518px;

  padding: 30px 27px 40px 30px;

  display: flex;
  background-image: url('${leftBlockBg}');
  background-repeat: no-repeat;
  background-size: contain;

  ${({ theme }) => theme.adaptive.dashboard.xxl} {
    width: 1160px;
    background-image: url('${leftBlockBgScale}');
  }

  ${({ theme }) => theme.adaptive.dashboard.xl} {
    width: 992px;
    height: 443px;

    padding: 25px 23px 34px 25px;
  }

  ${({ theme }) => theme.adaptive.dashboard.lg} {
    width: 874px;
    height: 391px;

    padding: 22px 20px 30px 22px;
  }

  ${({ theme }) => theme.adaptive.dashboard.sm} {
    width: 100%;
    height: 100%;

    padding: 0;
    background-image: none;
    flex-direction: column;
  }
`

const Bullets = styled.div`
  width: 440px;
  margin-right: 20px;

  ${({ theme }) => theme.adaptive.dashboard.xxl} {
    width: 310px;
  }

  ${({ theme }) => theme.adaptive.dashboard.xl} {
    width: 264px;
  }

  ${({ theme }) => theme.adaptive.dashboard.lg} {
    width: 233px;
  }

  ${({ theme }) => theme.adaptive.dashboard.sm} {
    width: 100%;
    margin-right: 0;
  }

  color: #ebebeb;
`

const Bullet = styled.div`
  border: 1px solid rgba(92, 93, 97, 0.3);
  border-radius: 10px;

  padding: 32px;
  margin-bottom: 20px;

  font-size: 18px;
  line-height: 22px;
  font-weight: 300;

  ${({ theme }) => theme.adaptive.dashboard.xxl} {
    padding: 24px;

    font-size: 16px;
    line-height: 19px;
  }

  ${({ theme }) => theme.adaptive.dashboard.xl} {
    padding: 20px;

    font-size: 13px;
    line-height: 16px;
  }

  ${({ theme }) => theme.adaptive.dashboard.lg} {
    padding: 18px;

    font-size: 12px;
    line-height: 14px;
  }

  ${({ theme }) => theme.adaptive.dashboard.sm} {
    padding: 18px;

    font-size: 16px;
    line-height: 19px;
  }
`

const UL = styled.ul`
  margin: 0;
  padding-left: 18px;

  list-style: none;
`

const LI = styled.li`
  &:not(:last-child) {
    margin-bottom: 16px;
  }

  position: relative;

  &::before {
    content: '';
    width: 1px;
    height: 1px;
    left: -16px;
    top: 8px;
    background-color: #b392ff;
    position: absolute;
  }
`

const BulletSmallText = styled.span`
  color: #939ba9;
`

const MediumLinkContainer = styled.div`
  text-align: center;

  ${({ theme }) => theme.adaptive.dashboard.xxl} {
    display: none;
  }
`

const MediumLink = styled.a`
  color: #939ba9;
`

const Panel = styled.div`
  flex-grow: 1;
`

const Numbers = styled.div`
  display: flex;

  margin-bottom: 20px;

  ${({ theme }) => theme.adaptive.dashboard.sm} {
    flex-direction: column;
  }
`

const OCXLeftAndDelegators = styled.div`
  display: flex;
`

const InteractivePanel = styled.div`
  display: flex;
  justify-content: space-between;
`

const StyledChart = styled(Chart)`
  transform: translateY(-40px);

  ${({ theme }) => theme.adaptive.dashboard.xl} {
    transform: translateY(-30px);
  }

  ${({ theme }) => theme.adaptive.dashboard.sm} {
    transform: translateY(0);
  }
`

const MobileChartWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`

const RightBlock = styled.div`
  width: 490px;
  height: 518px;

  padding: 30px 30px 50px 50px;

  background-image: url('${rightBlockBg}');
  background-repeat: no-repeat;
  background-size: contain;

  display: flex;
  flex-direction: column;

  ${({ theme }) => theme.adaptive.dashboard.xxl} {
    width: 420px;
    background-image: url('${rightBlockBgScale}');
  }

  ${({ theme }) => theme.adaptive.dashboard.xl} {
    width: 358px;
    height: 442px;

    padding: 25px 25px 42px 42px;
  }

  ${({ theme }) => theme.adaptive.dashboard.lg} {
    width: 316px;
    height: 390px;

    padding: 22px 22px 37px 37px;
  }

  ${({ theme }) => theme.adaptive.dashboard.md} {
    margin-top: 20px;

    width: 636px;
    height: 240px;

    padding: 30px 43px 41px 50px;

    background-image: url('${rightBlockBg920}');
    flex-direction: row;
    justify-content: space-between;
  }

  ${({ theme }) => theme.adaptive.dashboard.sm} {
    display: none;
  }
`

const RightBlockButtons = styled.div`
  margin-top: 40px;

  ${({ theme }) => theme.adaptive.dashboard.xl} {
    margin-top: 35px;
  }

  ${({ theme }) => theme.adaptive.dashboard.lg} {
    margin-top: 30px;
  }

  ${({ theme }) => theme.adaptive.dashboard.md} {
    margin-top: auto;
    margin-bottom: auto;

    width: 256px;
  }

  text-align: center;
`

const RightBlockButton = styled.div`
  border: 1px solid #394045;
  border-radius: 10px;
  ${({ borderColor }) =>
    borderColor &&
    css`
      border-color: ${borderColor};
    `};

  padding: 12px;
  margin-top: 20px;

  color: #ebebeb;
  font-size: 20px;
  line-height: 24px;

  display: flex;
  justify-content: center;
  align-items: center;

  ${({ theme }) => theme.adaptive.dashboard.xl} {
    font-size: 17px;
    line-height: 20px;

    padding: 9px;
    margin-top: 17px;
  }

  ${({ theme }) => theme.adaptive.dashboard.lg} {
    font-size: 15px;
    line-height: 18px;

    margin-top: 15px;
  }
`

const RightBlockLink = styled.a`
  color: #939ba9;
  background-color: rgba(218, 242, 247, 0.07);
  font-size: 16px;
  font-weight: 300;
  line-height: 19px;
  border-radius: 10px;

  position: relative;
  padding: 8px 50px 8px 12px;

  &::before {
    content: '';
    width: 29px;
    height: 19px;

    position: absolute;
    top: 6px;
    right: 12px;

    background-image: url('${youTubeIcon}');
    background-size: contain;
    background-repeat: no-repeat;

    ${({ theme }) => theme.adaptive.dashboard.xl} {
      width: 23px;
      height: 15px;
    }

    ${({ theme }) => theme.adaptive.dashboard.lg} {
      width: 21px;
      height: 13px;
    }
  }

  ${({ theme }) => theme.adaptive.dashboard.xl} {
    font-size: 12px;
    line-height: 14px;

    padding-right: 45px;
  }

  ${({ theme }) => theme.adaptive.dashboard.lg} {
    font-size: 11px;
    line-height: 13px;

    padding-right: 43px;
  }
`
