import React from 'react'
import styled, { css } from 'styled-components'
import { Link } from 'react-router-dom'
import footerBg from 'img/new/footer/bg.png'
import clouds from 'img/new/footer/clouds.png'
import cloud from 'img/new/footer/cloud.png'
import cloudsSm from 'img/new/footer/clouds-sm.png'
import cloudsSmm from 'img/new/footer/clouds-smm.png'
import mountainImg from 'img/new/footer/mountain.png'
import socialsStore from 'store/socials'
import {
  APPLY_IDO_LINK,
  CAREERS_LINK,
  DAO_LINK,
  FAQ_LINK,
  INCUBATOR_LINK,
  ISPO_LINK,
  OCCAMX_DECK,
  OCCAMX_LINK,
  RAZER_LINK,
} from 'configs/app.config'

const Footer: React.FunctionComponent = () => {
  return (
    <Root>
      <White />
      <Container>
        <Cont>
          <Blur />
          <SecondBlur />
          <Row>
            <Bg>
              <BgText>Interchain Smart DeFi Layer</BgText>
            </Bg>
            <Links>
              <Link to={{ pathname: OCCAMX_DECK }} target={'_blank'}>
                OccamX Deck
              </Link>
              <Link to={{ pathname: APPLY_IDO_LINK }} target={'_blank'}>
                Apply for IDO
              </Link>
              <Link to={{ pathname: FAQ_LINK }} target={'_blank'}>
                FAQ
              </Link>
              <Link to={{ pathname: CAREERS_LINK }} target={'_blank'}>
                Careers
              </Link>
              <Link to={{ pathname: ISPO_LINK }} target={'_blank'}>
                ISPO Infrastructure
              </Link>
            </Links>
            <MainLinkRow>
              <MainLink>
                <Link to={{ pathname: DAO_LINK }} target={'_blank'}>
                  DAO
                </Link>
              </MainLink>
              <MainLink>
                <Link to={{ pathname: RAZER_LINK }} target={'_blank'}>
                  Razer
                </Link>
              </MainLink>
              <MainLink>
                <Link to={{ pathname: OCCAMX_LINK }} target={'_blank'}>
                  OccamX
                </Link>
              </MainLink>
              <MainLink>
                <Link to={{ pathname: INCUBATOR_LINK }} target={'_blank'}>
                  Incubator
                </Link>
              </MainLink>
            </MainLinkRow>
          </Row>
          <Bottom>
            <Socials>
              {socialsStore.map((social, idx) => {
                return (
                  <SocialLink key={idx} href={social.link} target="_blank">
                    {social.getIcon('#939BA9')}
                  </SocialLink>
                )
              })}
            </Socials>
            <CopyRight>OccamX DAO</CopyRight>
          </Bottom>
          <Clouds />
          <Mountain />
          <MobileClouds />
        </Cont>
      </Container>
    </Root>
  )
}

export default Footer

const Root = styled.div`
  position: relative;
  width: 100%;
  padding-top: 680px;
  padding-bottom: 31px;
  ${({ theme }) => theme.adaptive.newDesign.xl} {
    padding-top: 597px;
    padding-bottom: 27px;
  }

  ${({ theme }) => theme.adaptive.newDesign.lg} {
    padding-top: 512px;
    padding-bottom: 23px;
  }

  ${({ theme }) => theme.adaptive.newDesign.slg} {
    padding-top: 441px;
    padding-bottom: 20px;
  }

  ${({ theme }) => theme.adaptive.newDesign.md} {
    padding-top: 327px;
    padding-bottom: 15px;
  }

  ${({ theme }) => theme.adaptive.newDesign.sm} {
    padding-top: 701px;
    padding-bottom: 29px;
  }

  ${({ theme }) => theme.adaptive.newDesign.smm} {
    padding-top: 711px;
    padding-bottom: 22px;
  }

  ${({ theme }) => theme.adaptive.newDesign.xs} {
    padding-top: 554px;
  }
`

const Container = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-end;
  width: 1598px;
  margin: 0 auto;

  ${({ theme }) => theme.adaptive.newDesign.xl} {
    width: 1399px;
  }

  ${({ theme }) => theme.adaptive.newDesign.lg} {
    width: 1199px;
  }

  ${({ theme }) => theme.adaptive.newDesign.slg} {
    width: 1034px;
  }

  ${({ theme }) => theme.adaptive.newDesign.md} {
    width: 773px;
  }

  ${({ theme }) => theme.adaptive.newDesign.sm} {
    justify-content: center;
    width: 544px;
  }

  ${({ theme }) => theme.adaptive.newDesign.smm} {
    width: 335px;
  }

  ${({ theme }) => theme.adaptive.newDesign.xs} {
    width: 280px;
  }
`

const Cont = styled.div`
  position: relative;
  display: block;
  width: 852px;

  ${({ theme }) => theme.adaptive.newDesign.xl} {
    width: 745px;
  }

  ${({ theme }) => theme.adaptive.newDesign.lg} {
    width: 637px;
  }

  ${({ theme }) => theme.adaptive.newDesign.slg} {
    width: 552px;
  }

  ${({ theme }) => theme.adaptive.newDesign.md} {
    width: 409px;
  }

  ${({ theme }) => theme.adaptive.newDesign.sm} {
    width: 100%;
  }
`

const Row = styled.div`
  position: relative;
  display: block;
  margin-bottom: 147px;
  height: 305px;
  ${({ theme }) => theme.adaptive.newDesign.xl} {
    margin-bottom: 135px;
    height: 264px;
  }

  ${({ theme }) => theme.adaptive.newDesign.lg} {
    margin-bottom: 107px;
    height: 229px;
  }

  ${({ theme }) => theme.adaptive.newDesign.slg} {
    margin-bottom: 100px;
    height: 195px;
  }

  ${({ theme }) => theme.adaptive.newDesign.md} {
    margin-bottom: 75px;
    height: 145px;
  }

  ${({ theme }) => theme.adaptive.newDesign.sm} {
    margin-bottom: 64px;
    height: auto;
  }

  ${({ theme }) => theme.adaptive.newDesign.smm} {
    margin-bottom: 48px;
  }

  ${({ theme }) => theme.adaptive.newDesign.xs} {
    margin-bottom: 44px;
  }
`

const Bg = styled.div`
  position: absolute;
  left: -1434px;
  top: -724px;
  width: 1669px;
  height: 1669px;
  background: url('${footerBg}') center no-repeat;
  background-size: cover;
  ${({ theme }) => theme.adaptive.newDesign.xl} {
    left: -1255px;
    top: -634px;
    width: 1460px;
    height: 1460px;
  }
  ${({ theme }) => theme.adaptive.newDesign.lg} {
    left: -1076px;
    top: -543px;
    width: 1252px;
    height: 1252px;
  }
  ${({ theme }) => theme.adaptive.newDesign.slg} {
    left: -926px;
    top: -468px;
    width: 1078px;
    height: 1078px;
  }
  ${({ theme }) => theme.adaptive.newDesign.md} {
    left: -687px;
    top: -347px;
    width: 800px;
    height: 800px;
  }
  ${({ theme }) => theme.adaptive.newDesign.sm} {
    left: -131px;
    top: -651px;
  }
  ${({ theme }) => theme.adaptive.newDesign.smm} {
    left: -226px;
  }
  ${({ theme }) => theme.adaptive.newDesign.xs} {
    left: -174px;
    top: -508px;
    width: 624px;
    height: 624px;
  }
`

const BgText = styled.div`
  position: absolute;
  top: 735px;
  left: 736px;
  color: #939ba9;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  ${({ theme }) => theme.adaptive.newDesign.xl} {
    top: 643px;
    left: 644px;
    font-size: 16px;
    line-height: 21px;
  }
  ${({ theme }) => theme.adaptive.newDesign.lg} {
    top: 551px;
    left: 533px;
    font-size: 16px;
    line-height: 18px;
  }
  ${({ theme }) => theme.adaptive.newDesign.slg} {
    top: 465px;
    left: 466px;
    font-size: 14px;
    line-height: 16px;
  }
  ${({ theme }) => theme.adaptive.newDesign.md} {
    top: 351px;
    left: 335px;
    font-size: 12px;
    line-height: 12px;
  }
  ${({ theme }) => theme.adaptive.newDesign.sm} {
    top: 341px;
    left: 334px;
    font-size: 12px;
    line-height: 16px;
  }
  ${({ theme }) => theme.adaptive.newDesign.xs} {
    top: 266px;
    left: 245px;
    font-size: 12px;
    line-height: 12px;
  }
`

const Mountain = styled.div`
  position: absolute;
  left: -1529px;
  top: 30px;
  width: 1641px;
  height: 570px;
  background: url('${mountainImg}') center no-repeat;
  background-size: cover;

  ${({ theme }) => theme.adaptive.newDesign.xl} {
    left: -1338px;
    top: 32px;
    width: 1436px;
    height: 499px;
  }

  ${({ theme }) => theme.adaptive.newDesign.lg} {
    left: -1147px;
    top: 17px;
    width: 1231px;
    height: 428px;
  }

  ${({ theme }) => theme.adaptive.newDesign.slg} {
    left: -988px;
    top: 23px;
    width: 1060px;
    height: 368px;
  }

  ${({ theme }) => theme.adaptive.newDesign.md} {
    left: -733px;
    top: 20px;
    width: 786px;
    height: 273px;
  }

  ${({ theme }) => theme.adaptive.newDesign.sm} {
    left: -309px;
    top: -305px;
    width: 1017px;
    height: 354px;
  }

  ${({ theme }) => theme.adaptive.newDesign.smm} {
    left: -400px;
    top: -309px;
  }

  ${({ theme }) => theme.adaptive.newDesign.xs} {
    left: -309px;
    top: -241px;
    width: 794px;
    height: 276px;
  }
`

const LinkCss = css`
  z-index: 2;
  width: 213px;

  ${({ theme }) => theme.adaptive.newDesign.xl} {
    width: 186px;
  }

  ${({ theme }) => theme.adaptive.newDesign.lg} {
    width: 159px;
  }

  ${({ theme }) => theme.adaptive.newDesign.slg} {
    width: 138px;
  }

  ${({ theme }) => theme.adaptive.newDesign.md} {
    width: 102px;
  }

  ${({ theme }) => theme.adaptive.newDesign.sm} {
    width: 120px;
  }

  ${({ theme }) => theme.adaptive.newDesign.smm} {
    width: 80px;
  }

  ${({ theme }) => theme.adaptive.newDesign.xs} {
    width: 70px;
  }
`

const Links = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 105px;
  ${({ theme }) => theme.adaptive.newDesign.xl} {
    margin-bottom: 96px;
  }

  ${({ theme }) => theme.adaptive.newDesign.lg} {
    margin-bottom: 82px;
  }

  ${({ theme }) => theme.adaptive.newDesign.slg} {
    margin-bottom: 71px;
  }

  ${({ theme }) => theme.adaptive.newDesign.md} {
    margin-bottom: 52px;
  }

  ${({ theme }) => theme.adaptive.newDesign.sm} {
    margin-bottom: 30px;
  }

  ${({ theme }) => theme.adaptive.newDesign.smm} {
    margin-bottom: 61px;
  }

  ${({ theme }) => theme.adaptive.newDesign.xs} {
    margin-bottom: 56px;
  }

  a {
    ${LinkCss};
    display: block;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: #939ba9;
    opacity: 0.7;
    margin-bottom: 45px;
    transition: 0.3s ease;

    ${({ theme }) => theme.adaptive.newDesign.xl} {
      width: 186px;
      font-size: 20px;
      line-height: 24px;
      margin-bottom: 35px;
    }

    ${({ theme }) => theme.adaptive.newDesign.lg} {
      width: 159px;
      font-size: 18px;
      line-height: 22px;
      margin-bottom: 25px;
    }

    ${({ theme }) => theme.adaptive.newDesign.slg} {
      width: 138px;
      font-size: 16px;
      line-height: 19px;
      margin-bottom: 24px;
    }

    ${({ theme }) => theme.adaptive.newDesign.md} {
      width: 102px;
      font-size: 12px;
      line-height: 14px;
      margin-bottom: 18px;
    }

    ${({ theme }) => theme.adaptive.newDesign.sm} {
      font-size: 16px;
      line-height: 140%;
      margin-bottom: 18px;
      width: 33%;
      color: #5e5e5e;
    }

    ${({ theme }) => theme.adaptive.newDesign.xs} {
      font-size: 13px;
      line-height: 14px;
      margin-bottom: 20px;
      width: 91px;
    }

    &:last-child {
      margin-bottom: 0;
    }

    &:hover {
      color: #252525;
    }
  }
`

const MainLinkRow = styled.div`
  display: flex;
  ${({ theme }) => theme.adaptive.newDesign.sm} {
    justify-content: space-between;
  }
`

interface linkProps {
  hide?: boolean
}

const MainLink = styled.div<linkProps>`
  ${LinkCss};
  ${({ theme }) => theme.adaptive.newDesign.sm} {
    width: auto;
  }
  a {
    width: fit-content;
    position: relative;
    display: block;
    font-weight: 300;
    font-size: 24px;
    line-height: 30px;
    color: #ffffff;
    z-index: 2;
    opacity: ${({ hide }) => (hide ? 0.5 : 1)};
    pointer-events: ${({ hide }) => (hide ? 'none' : 'auto')};
    transition: 0.3s ease;

    ${({ theme }) => theme.adaptive.newDesign.lg} {
      font-size: 22px;
      line-height: 26px;
    }

    ${({ theme }) => theme.adaptive.newDesign.slg} {
      font-size: 19px;
      line-height: 24px;
    }

    ${({ theme }) => theme.adaptive.newDesign.md} {
      font-size: 14px;
      line-height: 18px;
    }

    ${({ theme }) => theme.adaptive.newDesign.sm} {
      color: #252525;
      font-size: 16px;
      line-height: 114%;
    }

    ${({ theme }) => theme.adaptive.newDesign.xs} {
      font-size: 14px;
      line-height: 18px;
    }

    &:hover {
      color: #5e5e5e;
    }

    &:before {
      content: '';
      position: absolute;
      left: 0;
      bottom: -5px;
      width: 100%;
      height: 1px;
      opacity: 0.15;
      background: #252525;
    }
  }
`

const Bottom = styled.div`
  position: relative;
  display: flex;
  align-items: center;

  ${({ theme }) => theme.adaptive.newDesign.sm} {
    display: block;
  }
`

const Socials = styled.div`
  display: flex;
  margin-right: 109px;
  z-index: 2;

  ${({ theme }) => theme.adaptive.newDesign.xl} {
    margin-right: 58px;
  }

  ${({ theme }) => theme.adaptive.newDesign.lg} {
    margin-right: 44px;
  }

  ${({ theme }) => theme.adaptive.newDesign.slg} {
    margin-right: 36px;
  }

  ${({ theme }) => theme.adaptive.newDesign.md} {
    margin-right: 20px;
  }

  ${({ theme }) => theme.adaptive.newDesign.sm} {
    width: 440px;
    margin-bottom: 42px;
    justify-content: space-between;
  }

  ${({ theme }) => theme.adaptive.newDesign.smm} {
    width: 100%;
  }

  ${({ theme }) => theme.adaptive.newDesign.xs} {
    margin-bottom: 39px;
  }
`

const SocialLink = styled.a`
  position: relative;
  display: block;
  margin-right: 61px;
  z-index: 2;

  ${({ theme }) => theme.adaptive.newDesign.xl} {
    margin-right: 53px;
  }

  ${({ theme }) => theme.adaptive.newDesign.lg} {
    margin-right: 44px;
  }

  ${({ theme }) => theme.adaptive.newDesign.slg} {
    margin-right: 39px;
  }

  ${({ theme }) => theme.adaptive.newDesign.md} {
    margin-right: 28px;
  }

  ${({ theme }) => theme.adaptive.newDesign.smm} {
    margin-right: 0;
  }

  &:last-child {
    margin-right: 0;
  }

  svg {
    max-width: 31px;
    max-height: 24px;
    fill: #80858a;

    ${({ theme }) => theme.adaptive.newDesign.xl} {
      max-width: 26px;
      max-height: 21px;
    }

    ${({ theme }) => theme.adaptive.newDesign.lg} {
      max-width: 22px;
      max-height: 17px;
    }

    ${({ theme }) => theme.adaptive.newDesign.slg} {
      max-width: 19px;
      max-height: 15px;
    }

    ${({ theme }) => theme.adaptive.newDesign.md} {
      max-width: 14px;
      max-height: 11px;
    }

    ${({ theme }) => theme.adaptive.newDesign.sm} {
      max-width: 21px;
      max-height: 17px;
    }

    ${({ theme }) => theme.adaptive.newDesign.xs} {
      max-width: 18px;
      max-height: 15px;
    }
  }
`

const CopyRight = styled.div`
  position: relative;
  font-weight: 300;
  font-size: 18px;
  line-height: 22px;
  text-align: right;
  color: #939ba9;
  opacity: 0.5;
  z-index: 2;

  ${({ theme }) => theme.adaptive.newDesign.xl} {
    font-size: 18px;
    line-height: 22px;
  }

  ${({ theme }) => theme.adaptive.newDesign.lg} {
    font-size: 16px;
    line-height: 20px;
  }

  ${({ theme }) => theme.adaptive.newDesign.slg} {
    font-size: 14px;
    line-height: 18px;
  }

  ${({ theme }) => theme.adaptive.newDesign.md} {
    font-size: 12px;
    line-height: 10px;
  }

  ${({ theme }) => theme.adaptive.newDesign.sm} {
    line-height: 14px;
    text-align: left;
    color: #617580;
  }

  ${({ theme }) => theme.adaptive.newDesign.smm} {
    font-size: 16px;
    line-height: 14px;
  }

  ${({ theme }) => theme.adaptive.newDesign.xs} {
    font-size: 14px;
    line-height: 14px;
  }
`

const Clouds = styled.div`
  position: absolute;
  top: -759px;
  left: -1235px;
  width: 2258px;
  height: 770px;
  background: url('${clouds}') center no-repeat;
  background-size: cover;
  ${({ theme }) => theme.adaptive.newDesign.xl} {
    top: -664px;
    left: -1081px;
    width: 1976px;
    height: 674px;
  }

  ${({ theme }) => theme.adaptive.newDesign.lg} {
    top: -569px;
    left: -926px;
    width: 1694px;
    height: 577px;
  }

  ${({ theme }) => theme.adaptive.newDesign.slg} {
    top: -490px;
    left: -798px;
    width: 1458px;
    height: 497px;
  }

  ${({ theme }) => theme.adaptive.newDesign.md} {
    top: -364px;
    left: -592px;
    width: 1082px;
    height: 369px;
  }

  ${({ theme }) => theme.adaptive.newDesign.sm} {
    top: -629px;
    left: 252px;
    width: 407px;
    height: 229px;
    background: url('${cloud}') center no-repeat;
    background-size: cover;
  }

  ${({ theme }) => theme.adaptive.newDesign.smm} {
    top: -670px;
    left: 99px;
    width: 437px;
    height: 246px;
  }

  ${({ theme }) => theme.adaptive.newDesign.xs} {
    top: -513px;
    left: 72px;
    width: 341px;
    height: 192px;
  }
`

const Blur = styled.div`
  position: absolute;
  top: -642px;
  left: -593px;
  width: 688px;
  height: 688px;
  background: #5196ff;
  opacity: 0.4;
  filter: blur(300px);
  transform: translate3d(0, 0, 0);
  ${({ theme }) => theme.adaptive.newDesign.xl} {
    top: -562px;
    left: -519px;
    width: 602px;
    height: 602px;
    filter: blur(260px);
  }
  ${({ theme }) => theme.adaptive.newDesign.lg} {
    top: -481px;
    left: -445px;
    width: 516px;
    height: 516px;
    filter: blur(225px);
  }
  ${({ theme }) => theme.adaptive.newDesign.slg} {
    top: -415px;
    left: -383px;
    width: 444px;
    height: 444px;
    filter: blur(194px);
  }
  ${({ theme }) => theme.adaptive.newDesign.md} {
    top: -308px;
    left: -284px;
    width: 330px;
    height: 330px;
    filter: blur(143px);
  }
  ${({ theme }) => theme.adaptive.newDesign.sm} {
    display: none;
  }
`

const SecondBlur = styled.div`
  position: absolute;
  top: -603px;
  left: 450px;
  width: 443px;
  height: 443px;
  background: #6ca7ff;
  opacity: 0.7;
  filter: blur(300px);
  transform: translate3d(0, 0, 0);
  ${({ theme }) => theme.adaptive.newDesign.xl} {
    top: -528px;
    left: 395px;
    width: 388px;
    height: 388px;
    filter: blur(260px);
  }
  ${({ theme }) => theme.adaptive.newDesign.lg} {
    top: -450px;
    left: 340px;
    width: 332px;
    height: 332px;
    filter: blur(225px);
  }
  ${({ theme }) => theme.adaptive.newDesign.slg} {
    top: -390px;
    left: 292px;
    width: 286px;
    height: 286px;
    filter: blur(194px);
  }
  ${({ theme }) => theme.adaptive.newDesign.md} {
    top: -288px;
    left: 217px;
    width: 212px;
    height: 212px;
    filter: blur(143px);
  }
  ${({ theme }) => theme.adaptive.newDesign.sm} {
    display: none;
  }
`

const White = styled.div`
  position: absolute;
  display: none;
  ${({ theme }) => theme.adaptive.newDesign.sm} {
    display: block;
    bottom: -49px;
    left: 0;
    width: 100%;
    height: 384px;
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.547611) 3.4%,
      rgba(255, 255, 255, 0.79733) 6.59%,
      rgba(255, 255, 255, 0.91534) 9.44%,
      rgba(255, 255, 255, 0.961903) 10.96%,
      #ffffff 13.32%
    );
  }
  ${({ theme }) => theme.adaptive.newDesign.xs} {
    bottom: -5px;
    height: 300px;
  }
`

const MobileClouds = styled.div`
  display: none;
  ${({ theme }) => theme.adaptive.newDesign.sm} {
    position: absolute;
    display: block;
    top: -84px;
    left: -198px;
    width: 914px;
    height: 251px;
    background: url('${cloudsSm}') center no-repeat;
    background-size: cover;
  }
  ${({ theme }) => theme.adaptive.newDesign.smm} {
    top: -84px;
    left: -116px;
    width: 569px;
    height: 251px;
    background: url('${cloudsSmm}') center no-repeat;
    background-size: cover;
  }
  ${({ theme }) => theme.adaptive.newDesign.xs} {
    top: -65px;
    left: -81px;
    width: 425px;
    height: 196px;
  }
`
