import { createGlobalStyle } from 'styled-components'
import NeueHaasDisplayWoff2Bold from './fonts/NeueHaasDisplay-Bold.woff2'
import NeueHaasDisplayWoffBold from './fonts/NeueHaasDisplay-Bold.woff'
import NeueHaasDisplayWoff2Thin from './fonts/NeueHaasDisplay-Thin.woff2'
import NeueHaasDisplayWoffThin from './fonts/NeueHaasDisplay-Thin.woff'
import NeueHaasDisplayWoff2Light from './fonts/NeueHaasDisplay-Light.woff2'
import NeueHaasDisplayWoffLight from './fonts/NeueHaasDisplay-Light.woff'
import NeueHaasDisplayWoff2Regular from './fonts/NeueHaasDisplay-Roman.woff2'
import NeueHaasDisplayWoffRegular from './fonts/NeueHaasDisplay-Roman.woff'
import NeueHaasDisplayWoff2Medium from './fonts/NeueHaasDisplay-Medium.woff2'
import NeueHaasDisplayWoffMedium from './fonts/NeueHaasDisplay-Medium.woff'
import RobotoMonoTTFExtraLight from './fonts/RobotoMono-ExtraLight.ttf'
import RPBrawlWoff2Regular from './fonts/RPBRAWL-Regular.woff2'
import RPBrawlWoffRegular from './fonts/RPBRAWL-Regular.woff'

// Neue Haas Display Display Pro
const neueHassDisplay = {
  thin: {
    woff2: NeueHaasDisplayWoff2Thin,
    woff: NeueHaasDisplayWoffThin,
  },
  light: {
    woff2: NeueHaasDisplayWoff2Light,
    woff: NeueHaasDisplayWoffLight,
  },
  regular: {
    woff2: NeueHaasDisplayWoff2Regular,
    woff: NeueHaasDisplayWoffRegular,
  },
  medium: {
    woff2: NeueHaasDisplayWoff2Medium,
    woff: NeueHaasDisplayWoffMedium,
  },
  bold: {
    woff2: NeueHaasDisplayWoff2Bold,
    woff: NeueHaasDisplayWoffBold,
  },
}

// Roboto Mono
const RobotoMono = {
  extraLight: {
    ttf: RobotoMonoTTFExtraLight,
  },
}

// RPBrawl
const RPBrawl = {
  regular: {
    woff2: RPBrawlWoff2Regular,
    woff: RPBrawlWoffRegular,
  },
}

const Fonts = createGlobalStyle`
  @font-face {
    font-family: 'NeueHaasDisplay';
    src: local('NeueHaasDisplay'),
    url(${neueHassDisplay.light.woff2}) format('woff2'),
    url(${neueHassDisplay.light.woff}) format('woff');
    font-weight: 300;
    font-style: normal;
  }

  @font-face {
    font-family: 'NeueHaasDisplay';
    src: local('NeueHaasDisplay'),
    url(${neueHassDisplay.thin.woff2}) format('woff2'),
    url(${neueHassDisplay.thin.woff}) format('woff');
    font-weight: 200;
    font-style: normal;
  }

  @font-face {
    font-family: 'NeueHaasDisplay';
    src: local('NeueHaasDisplay'),
    url(${neueHassDisplay.regular.woff2}) format('woff2'),
    url(${neueHassDisplay.regular.woff}) format('woff');
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: 'NeueHaasDisplay';
    src: local('NeueHaasDisplay'),
    url(${neueHassDisplay.bold.woff2}) format('woff2'),
    url(${neueHassDisplay.bold.woff}) format('woff');
    font-weight: 700;
    font-style: normal;
  }

  @font-face {
    font-family: 'NeueHaasDisplay';
    src: local('NeueHaasDisplay'),
    url(${neueHassDisplay.medium.woff2}) format('woff2'),
    url(${neueHassDisplay.medium.woff}) format('woff');
    font-weight: 500;
    font-style: normal;
  }

  @font-face {
    font-family: 'Roboto Mono';
    src: local('Roboto Mono'),
    url(${RobotoMono.extraLight.ttf}) format('truetype');
    font-weight: 200;
    font-style: normal;
  }

  @font-face {
    font-family: 'RPBrawl';
    src: local('RPBrawl'),
    url(${RPBrawl.regular.woff2}) format('woff2'),
    url(${RPBrawl.regular.woff}) format('woff');
    font-weight: 400;
    font-style: normal;
  }
`
export default Fonts
