import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
  }

  body, html, #root {
    min-height: 100vh;
    scroll-behavior: smooth;
  }

  body {
    min-height: 100vh;
    min-height: -webkit-fill-available;
    margin: 0;
    font-family: 'NeueHaasDisplay', Arial;
    -webkit-font-smoothing: antialiased;
  }

  button {
    border: 0;
    background: none;
    padding: 0;
    font-family: 'NeueHaasDisplay';
    outline: none;
    cursor: pointer;
  }

  input {
    background: none;
    border-radius: 8px;
    outline: none;
  }

  textarea {
    outline: none;
    font-family: 'NeueHaasDisplay';
  }

  .ReactCollapse--collapse {
    transition: height 800ms;
    width: 100%;
    height: 100%;
  }

  .team-tooltip {
    padding: 20px !important;
    max-width: 50%;

    ${({ theme }) => theme.adaptive.sm} {
      max-width: 75%;
      padding: 15px !important;
    }
  }

  a {
    text-decoration: none;
  }
`
export default GlobalStyle
