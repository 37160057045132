import React from 'react'

import styled from 'styled-components'

import ocxLeftToDistributeBg from '../../img/dashboard/ocx-left-to-distribute-bg.svg'

import Counter from './Counter'
import useWindowSize from '../../helpers/utils/useWindowSize'

export default function OcxLeftToDistribute({ ocx }) {
  const { width } = useWindowSize()
  const isSmall = width <= 920

  return (
    <OCXLeftToDistribute>
      <Counter holes={10} small={isSmall} end={ocx} />
      <OCXLeftToDistributeText>
        <span>OCX left to allocate</span>
      </OCXLeftToDistributeText>
    </OCXLeftToDistribute>
  )
}

const OCXLeftToDistribute = styled.div`
  width: 303px;
  height: 150px;

  margin-right: 12px;
  padding: 30px 12px 12px 12px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  background-image: url('${ocxLeftToDistributeBg}');
  background-repeat: no-repeat;
  background-size: contain;

  ${({ theme }) => theme.adaptive.dashboard.xl} {
    width: 258px;
    height: 128px;

    margin-right: 10px;
    padding: 25px 10px 10px 10px;
  }

  ${({ theme }) => theme.adaptive.dashboard.lg} {
    width: 228px;
    height: 112px;

    margin-right: 9px;
    padding: 22px 9px 9px 9px;
  }

  ${({ theme }) => theme.adaptive.dashboard.sm} {
    width: 189px;
    height: 93px;

    margin-right: 10px;
    padding: 18px 7px 7px 7px;
  }
`

const OCXLeftToDistributeText = styled.div`
  font-weight: 300;
  color: #939ba9;

  ${({ theme }) => theme.adaptive.dashboard.xl} {
    font-size: 13px;
    line-height: 16px;
  }

  ${({ theme }) => theme.adaptive.dashboard.xl} {
    font-size: 12px;
    line-height: 14px;
  }

  ${({ theme }) => theme.adaptive.dashboard.sm} {
    font-size: 10px;
    line-height: 12px;
  }
`
