import React from 'react'
import styled from 'styled-components'

import totalBg from '../../img/dashboard/total-bg-1920.svg'
import totalBg1680 from '../../img/dashboard/total-bg-1680.svg'

import Counter from './Counter'

const formatter = new Intl.NumberFormat('en-US', {
  notation: 'compact',
  minimumFractionDigits: 0,
  maximumFractionDigits: 2,
  minimumSignificantDigits: 3,
  maximumSignificantDigits: 4,
})

export default function TotalAdaStaked({ ada }) {
  return (
    <TotalADAStaked>
      <Counter
        big
        holes={6}
        noSkipHoles
        end={ada}
        formattingFn={formatter.format}
      />
      <TotalADAStakedText>Total ADA staked</TotalADAStakedText>
    </TotalADAStaked>
  )
}

const TotalADAStaked = styled.div`
  width: 410px;
  height: 223px;

  padding: 30px 36px 20px 20px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  background-image: url('${totalBg}');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;

  ${({ theme }) => theme.adaptive.dashboard.xxl} {
    width: 340px;
    background-image: url('${totalBg1680}');
  }

  ${({ theme }) => theme.adaptive.dashboard.xl} {
    width: 290px;
    height: 190px;

    padding: 32px 25px 19px 17px;
  }

  ${({ theme }) => theme.adaptive.dashboard.lg} {
    width: 256px;
    height: 167px;

    padding: 28px 22px 15px 19px;
  }

  ${({ theme }) => theme.adaptive.dashboard.md} {
    margin-right: 30px;
  }
`

const TotalADAStakedText = styled.span`
  color: #939ba9;
  font-weight: 300;

  text-transform: capitalize;

  ${({ theme }) => theme.adaptive.dashboard.xl} {
    font-size: 12px;
    line-height: 14px;
  }
`
