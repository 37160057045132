import {
  combine,
  createEffect,
  createEvent,
  createStore,
  sample,
} from 'effector'

import doInit from '../api/dashboard/init'
import fetchPools from '../api/dashboard/pools'

const $totalOCX = createStore(10_000_000)

export const $ocxPerMinute = createStore(82.2)

const $startTimestamp = createStore(new Date('2021-09-09T21:15:00.000Z'))
const $currentTimestamp = createStore(new Date())
const $endTimestamp = createStore(new Date('2022-09-09T21:14:59.999Z'))

const timeElapsed = createEvent<number>()
export const secondElapsed = timeElapsed.prepend(() => 1)
$currentTimestamp.on(
  timeElapsed,
  (date, seconds) => new Date(date.setSeconds(date.getSeconds() + seconds))
)

export const $timeElapsedPercent = combine({
  start: $startTimestamp,
  current: $currentTimestamp,
  end: $endTimestamp,
}).map(({ start, current, end }) => {
  if (current < start) {
    return 0
  }

  if (current > end) {
    return 1
  }

  return (
    (current.valueOf() - start.valueOf()) / (end.valueOf() - start.valueOf())
  )
})

export const $ocxAllocated = sample({
  clock: $timeElapsedPercent,
  source: $totalOCX,
  fn: (totalOCX, timeElapsedPercent) => totalOCX * timeElapsedPercent,
})

export const $ocxLeft = sample({
  clock: $timeElapsedPercent,
  source: $totalOCX,
  fn: (totalOCX, timeElapsedPercent) => totalOCX * (1 - timeElapsedPercent),
})

export const initFx = createEffect(async () => (await doInit()).data)
$startTimestamp.on(
  initFx.doneData,
  (_, { start_timestamp }) => new Date(start_timestamp)
)
$currentTimestamp.on(
  initFx.doneData,
  (_, { current_timestamp }) => new Date(current_timestamp)
)
$endTimestamp.on(
  initFx.doneData,
  (_, { end_timestamp }) => new Date(end_timestamp)
)
$totalOCX.on(initFx.doneData, (_, { total_ocx }) => total_ocx)
$ocxPerMinute.on(initFx.doneData, (_, { ocx_per_minute }) => ocx_per_minute)

export const $delegators = createStore(8_562)
export const $totalADAStaked = createStore(18_560_000)

export const fetchPoolsFx = createEffect(async () => (await fetchPools()).data)
$delegators.on(
  fetchPoolsFx.doneData,
  (_, { total_unique_delegators }) => total_unique_delegators
)
$totalADAStaked.on(
  fetchPoolsFx.doneData,
  (_, { total_lovelace }) => +total_lovelace.slice(0, -6)
)
