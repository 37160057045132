import React, { useState } from 'react'
import styled from 'styled-components'
// @ts-ignore
import VisibilitySensor from 'react-visibility-sensor-v2'

interface partnerProps {
  link: string
  imageSrc: JSX.Element
}

const PartnersItem: React.FunctionComponent<partnerProps> = (props) => {
  const [isFade, setIsFade] = useState(false)

  function onChange(isVisible: any) {
    if (isVisible) {
      setIsFade(false)
    } else {
      setIsFade(true)
    }
  }

  return (
    <Item target={'_blank'} href={props.link} fade={isFade}>
      <VisibilitySensor onChange={onChange}>
        <ItemImg>{props.imageSrc}</ItemImg>
      </VisibilitySensor>
    </Item>
  )
}

export default PartnersItem

interface fadeProps {
  fade: boolean
}

const Item = styled.a<fadeProps>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 37px;
  width: 320px;
  height: auto;
  outline: none;
  text-decoration: none;
  z-index: 10;
  opacity: ${({ fade }) => (fade ? '0.5' : '1')};
  transition: 0.4s;
  ${({ theme }) => theme.adaptive.newIndex.xl} {
    width: 280px;
  }
  ${({ theme }) => theme.adaptive.newIndex.lg} {
    width: 240px;
  }
  ${({ theme }) => theme.adaptive.newIndex.md} {
    width: 206px;
  }
  ${({ theme }) => theme.adaptive.newIndex.smd} {
    width: 153px;
    margin: 0 23px;
  }
  ${({ theme }) => theme.adaptive.newIndex.sm} {
    width: 236px;
    margin-bottom: 35px;
  }
  ${({ theme }) => theme.adaptive.newIndex.smm} {
    width: 153px;
    margin-bottom: 20px;
  }
`

const ItemImg = styled.div`
  svg {
    display: block;
    max-height: 100%;
    max-width: 100%;
    margin: auto;
    ${({ theme }) => theme.adaptive.newIndex.sm} {
      max-width: 180px;
    }
    ${({ theme }) => theme.adaptive.newIndex.smm} {
      max-width: 120px;
    }
  }
`
