import React, { useEffect, useState } from 'react'
import Logo from '../../Logo'
import styled from 'styled-components'
import { Container } from '../../styles'
import Button from '../../Button'
import { HashLink as Link } from 'react-router-hash-link'
import { OCCAM_LINK, OCCAMX_DECK, ISPO_LINK } from 'configs/app.config'
import useWindowSize from 'helpers/utils/useWindowSize'
import mobileLogo from 'img/new/header/mobile-logo.svg'
import blurImg from 'img/new/header/blur.png'
import blurImgSm from 'img/new/header/blur-sm.png'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import HeaderBurger from './Burger'
import HeaderMenu from './HeaderMenu'

const Header: React.FunctionComponent = () => {
  const [active, setActive] = useState(false)
  const { width } = useWindowSize()
  const [animationStart, setAnimationStart] = useState(false)
  const [isAnimStarted, setIsAnimStarted] = useState(false)
  const body = document.body

  useEffect(() => {
    return () => {
      enableBodyScroll(body)
    }
  }, [])

  const onBurgerClick = () => {
    console.log('dsa')
    setActive(!active)
    if (!active) {
      window.scrollTo({ top: 0, behavior: 'smooth' })
      disableBodyScroll(body)
    } else {
      enableBodyScroll(body)
    }
    if (!isAnimStarted) {
      return animationStart ? setAnimationStart(false) : setAnimationStart(true)
    }
  }

  const navigationList = [
    {
      name: 'News',
      link: 'https://medium.com/occam-finance',
      isNewTab: true,
    },
    // {
    //   name: 'Roadmap',
    //   link: '#roadmap',
    // },
    {
      name: 'Back to Occam.fi',
      link: OCCAM_LINK,
      isNewTab: true,
    },
    // {
    //   name: 'Developers',
    //   link: OCCAM_LINK + '/team',
    //   isNewTab: true,
    // },
    {
      name: 'Community',
      link: 'https://t.me/occamfi_com',
      isNewTab: true,
    },
    {
      name: 'ISPO Infrastructure',
      link: ISPO_LINK,
      isNewTab: true,
    },
    {
      name: 'Deck',
      link: OCCAMX_DECK,
      isNewTab: true,
    },
  ]

  return (
    <Root>
      <Row>
        {width < 860 && <MobileLogo />}
        <Logo />
        <Navigation>
          {navigationList.map((nav, idx) => {
            return (
              <NavigationItem key={idx}>
                <Link
                  to={nav.isNewTab ? { pathname: nav.link } : nav.link}
                  target={nav.isNewTab ? '_blank' : ''}
                >
                  {nav.name}
                </Link>
              </NavigationItem>
            )
          })}
        </Navigation>
        <ButtonWrapper>{width > 860 && <Button />}</ButtonWrapper>
        {width < 860 && (
          <HeaderBurger
            animationStart={animationStart}
            onBurgerClick={() => onBurgerClick()}
            active={active}
          />
        )}
      </Row>
      {width < 860 && (
        <HeaderMenu
          list={navigationList}
          active={active}
          onClose={() => onBurgerClick()}
        />
      )}
    </Root>
  )
}

export default Header

const Root = styled.div`
  position: relative;
  padding-top: 29px;
  ${({ theme }) => theme.adaptive.newDesign.xl} {
    padding-top: 25px;
  }

  ${({ theme }) => theme.adaptive.newDesign.lg} {
    padding-top: 22px;
  }

  ${({ theme }) => theme.adaptive.newDesign.slg} {
    padding-top: 19px;
  }

  ${({ theme }) => theme.adaptive.newDesign.md} {
    padding-top: 14px;
  }

  ${({ theme }) => theme.adaptive.newDesign.sm} {
    padding-top: 19px;
  }

  ${({ theme }) => theme.adaptive.newDesign.xs} {
    padding-top: 15px;
  }
  &:before {
    content: '';
    position: absolute;
    top: -113px;
    left: 50%;
    transform: translateX(-50%);
    width: 2196px;
    height: 1360px;
    background: url('${blurImg}') center no-repeat;
    background-size: cover;
    ${({ theme }) => theme.adaptive.newDesign.xl} {
      top: -99px;
      width: 1922px;
      height: 1190px;
    }

    ${({ theme }) => theme.adaptive.newDesign.lg} {
      top: -85px;
      width: 1641px;
      height: 1020px;
    }

    ${({ theme }) => theme.adaptive.newDesign.slg} {
      top: -73px;
      width: 1418px;
      height: 878px;
    }
    ${({ theme }) => theme.adaptive.newDesign.md} {
      top: -54px;
      width: 1052px;
      height: 652px;
    }
    ${({ theme }) => theme.adaptive.newDesign.sm} {
      top: -69px;
      width: 652px;
      height: 1052px;
      background: url('${blurImgSm}') center no-repeat;
      background-size: cover;
    }
    ${({ theme }) => theme.adaptive.newDesign.xs} {
      top: -54px;
      width: 509px;
      height: 821px;
    }
  }
`

const Row = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 12;
  ${({ theme }) => theme.adaptive.newDesign.sm} {
    justify-content: space-between;
  }
`

const MobileLogo = styled.div`
  width: 30px;
  height: 19px;
  background: url('${mobileLogo}') center no-repeat;
  background-size: cover;
  ${({ theme }) => theme.adaptive.newDesign.xs} {
    width: 23px;
    height: 15px;
  }
`

const Navigation = styled.div`
  ${({ theme }) => theme.adaptive.occamx.smd} {
    display: none;
  }
`

const NavigationItem = styled.div`
  position: relative;
  display: inline-block;
  margin-left: 70px;
  z-index: 5;
  ${({ theme }) => theme.adaptive.newDesign.xl} {
    margin-left: 61px;
  }

  ${({ theme }) => theme.adaptive.newDesign.lg} {
    margin-left: 53px;
  }

  ${({ theme }) => theme.adaptive.newDesign.slg} {
    margin-left: 45px;
  }

  ${({ theme }) => theme.adaptive.newDesign.md} {
    margin-left: 34px;
  }

  &:first-child {
    margin-left: 0;
  }

  a {
    display: block;
    font-size: 18px;
    color: #ebebeb;

    ${({ theme }) => theme.adaptive.newDesign.xl} {
      font-size: 16px;
    }

    ${({ theme }) => theme.adaptive.newDesign.lg} {
      font-size: 14px;
    }

    ${({ theme }) => theme.adaptive.newDesign.md} {
      font-size: 12px;
    }
  }
`

const ButtonWrapper = styled.div`
  position: relative;
  z-index: 5;
  ${({ theme }) => theme.adaptive.occamx.smd} {
    display: none;
  }
`
