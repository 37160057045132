import React from 'react'

import styled from 'styled-components'

import ocxAllocatedBg from '../../img/dashboard/ocx-allocated-bg.svg'
import { percentFormatterLocaleAware } from '../../helpers/utils/numberFormatters'

import Counter from './Counter'

export default function OcxDistributed({ ocx, percent }) {
  return (
    <OCXDistributed>
      <Counter holes={10} end={ocx} />
      <OCXDistributedText>
        <span>OCX allocated</span>
        <OCXDistributedValue>
          {percentFormatterLocaleAware.format(percent)}
        </OCXDistributedValue>
      </OCXDistributedText>
    </OCXDistributed>
  )
}

const OCXDistributed = styled.div`
  width: 303px;
  height: 150px;

  margin-right: 12px;
  padding: 30px 12px 12px 12px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  background-image: url('${ocxAllocatedBg}');
  background-repeat: no-repeat;
  background-size: contain;

  ${({ theme }) => theme.adaptive.dashboard.xl} {
    width: 258px;
    height: 128px;

    margin-right: 10px;
    padding: 25px 10px 10px 10px;
  }

  ${({ theme }) => theme.adaptive.dashboard.lg} {
    width: 228px;
    height: 112px;

    margin-right: 9px;
    padding: 22px 9px 9px 9px;
  }

  ${({ theme }) => theme.adaptive.dashboard.sm} {
    width: 339px;
    height: 168px;

    margin-right: 0;
    margin-bottom: 30px;
    padding: 33px 13px 12px 13px;
  }
`

const OCXDistributedText = styled.div`
  color: #939ba9;

  display: flex;
  justify-content: space-between;

  padding-left: 8px;

  font-weight: 300;

  ${({ theme }) => theme.adaptive.dashboard.xl} {
    padding-left: 5px;

    font-size: 13px;
    line-height: 16px;
  }

  ${({ theme }) => theme.adaptive.dashboard.lg} {
    font-size: 12px;
    line-height: 14px;
  }

  ${({ theme }) => theme.adaptive.dashboard.sm} {
    font-size: 18px;
    line-height: 22px;
  }
`

const OCXDistributedValue = styled.span`
  transform: translateY(-7px);

  ${({ theme }) => theme.adaptive.dashboard.xl} {
    transform: translateY(-6px);
  }

  ${({ theme }) => theme.adaptive.dashboard.lg} {
    transform: translateY(-5px);
  }

  ${({ theme }) => theme.adaptive.dashboard.sm} {
    transform: translateY(-8px);
  }
`
