import React, { useEffect, useMemo, useState } from 'react'

import styled from 'styled-components'
import NumberFormat from 'react-number-format'
import throttle from 'lodash/throttle'

import { rewardOCX } from '../../api/dashboard/reward'
import { numberFormatterWithLocaleGrouping } from '../../helpers/utils/numberFormatters'
import calcBg from '../../img/dashboard/calc-bg.svg'

function CalculationReturnInputRow({
  prefix,
  suffix,
  value,
  onChange,
  readonly,
}) {
  return (
    <StyledCalculationReturnInputRow>
      {prefix}
      <CalculationReturnInput
        disabled={readonly}
        value={value}
        onValueChange={(data) => onChange(data.floatValue)}
        decimalScale={0}
        fixedDecimalScale
        format={numberFormatterWithLocaleGrouping.format}
        allowNegative={false}
      />
      {suffix}
    </StyledCalculationReturnInputRow>
  )
}

export default function CalculateReturn() {
  const [ada, setADA] = useState(undefined)
  const [ocx, setOCX] = useState(undefined)

  useEffect(async () => {
    const ada = 50_000
    const ocx = (await rewardOCX(`${ada}000000`)).data

    setADA(ada)
    setOCX(ocx)
  }, [])

  const handleADAChange = useMemo(
    () =>
      throttle(async (ada) => {
        setADA(ada)

        if (!ada) {
          setOCX(0)
          return
        }

        const ocx = (await rewardOCX(`${ada}000000`)).data
        setOCX(Math.round(ocx))
      }, 300),
    []
  )

  return (
    <StyledCalculateReturn>
      <div>
        Calculate your potential returns under current delegation status:
        <CalculationReturnInputRow
          prefix="Delegate"
          suffix="ADA"
          value={ada}
          onChange={handleADAChange}
        />
        <CalculationReturnInputRow
          prefix="for"
          suffix="OCX"
          value={ocx}
          onChange={setOCX}
          readonly
        />
      </div>
      <CalculateReturnDisclaimer>
        Calculator figures shown are dependent on current ADA delegated to our
        stake pools and time remaining for OCX distribution, and may not reflect
        the exact amount of OCX you receive
      </CalculateReturnDisclaimer>
    </StyledCalculateReturn>
  )
}

const StyledCalculateReturn = styled.div`
  width: 343px;
  height: 278px;

  padding: 20px 26px 26px 20px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  background-image: url('${calcBg}');
  background-repeat: no-repeat;
  background-size: contain;

  color: #939ba9;
  font-weight: 300;

  ${({ theme }) => theme.adaptive.dashboard.xl} {
    width: 293px;
    height: 237px;

    font-size: 13px;
    line-height: 16px;

    padding: 17px 22px 24px 17px;
  }

  ${({ theme }) => theme.adaptive.dashboard.lg} {
    width: 258px;
    height: 209px;

    font-size: 12px;
    line-height: 14px;

    padding: 15px 19px 22px 15px;
  }

  ${({ theme }) => theme.adaptive.dashboard.sm} {
    width: 343px;
    height: 278px;

    font-size: 16px;
    line-height: 19px;

    padding: 20px 26px 26px 20px;
  }
`

const CalculateReturnDisclaimer = styled.div`
  font-size: 14px;
  line-height: 16px;

  padding-right: 24px;

  ${({ theme }) => theme.adaptive.dashboard.xl} {
    font-size: 12px;
    line-height: 14px;

    padding-right: 22px;
  }

  ${({ theme }) => theme.adaptive.dashboard.lg} {
    font-size: 10px;
    line-height: 12px;

    padding-right: 17px;
  }

  ${({ theme }) => theme.adaptive.dashboard.sm} {
    font-size: 14px;
    line-height: 17px;

    padding-right: 14px;
  }
`

const StyledCalculationReturnInputRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;

  margin-top: 10px;
`

const CalculationReturnInput = styled(NumberFormat)`
  flex-grow: 1;

  background: rgba(0, 0, 0, 0.2);
  border: 1px solid rgba(57, 64, 69, 0.2);
  border-radius: 4px;

  height: calc(1em + 20px);
  padding: 10px;
  font-size: 14px;

  ${({ theme }) => theme.adaptive.dashboard.xl} {
    font-size: 12px;
    line-height: 14px;

    height: calc(1em + 16px);
    padding: 8px;
  }

  ${({ theme }) => theme.adaptive.dashboard.lg} {
    font-size: 11px;
    line-height: 13px;

    height: calc(1em + 14px);
    padding: 7px;
  }

  ${({ theme }) => theme.adaptive.dashboard.sm} {
    font-size: 14px;
    line-height: 17px;

    height: calc(1em + 20px);
    padding: 10px;
  }

  text-align: right;

  margin-left: 8px;
  margin-right: 8px;

  color: #ebebeb;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  -moz-appearance: textfield;

  &::placeholder {
    color: #394045;
  }
`
