import React from 'react'
import styled from 'styled-components'
import Index from 'components/Occamx/new/header'
import Main from './main'
import { Container } from './styles'
import Protocol from './protocol'
import Tokenomics from './tokenomics'
import News from './news'
import Partners from './partners'
import Footer from './footer'

const OccamX: React.FunctionComponent = () => {
  return (
    <Root>
      <Container>
        <Index />
        <Main />
        <Protocol />
        <Tokenomics />
        <News />
      </Container>
      <Partners />
      <Footer />
    </Root>
  )
}

export default OccamX

const Root = styled.div`
  position: relative;
  width: 100%;
  background: #202b4d;
  overflow: hidden;
`
