import React from 'react'
import styled from 'styled-components'

import IndexFooter from '../Index/IndexFooter'
import Title from './Title'
import Content from './Content'

export default function Dashboard({
  ocxPerMinute,
  timeElapsedPercent,
  ocxAllocated,
  ocxLeft,
  delegators,
  totalADAStaked,
}) {
  return (
    <Background>
      <ContentWrapper>
        <TitleRow>
          <Title>OccamX dashboard</Title>
          <SubTitleBlock>
            <SubTitle>
              Delegate ADA to earn OCX &mdash; the most promising Cardano DEX
              token
            </SubTitle>
          </SubTitleBlock>
        </TitleRow>
        <Content
          ocxPerMinute={ocxPerMinute}
          timeElapsedPercent={timeElapsedPercent}
          ocxAllocated={ocxAllocated}
          ocxLeft={ocxLeft}
          delegators={delegators}
          totalADAStaked={totalADAStaked}
        />
      </ContentWrapper>
      <IndexFooter
        bgColor={false}
        bgImage={false}
        backGround={false}
        copyright="OccamX DAO"
      />
    </Background>
  )
}

const Background = styled.div`
  background: linear-gradient(180deg, #1f2227 0%, #000000 100%);
  mix-blend-mode: multiply;
`

const ContentWrapper = styled.div`
  position: relative;
  z-index: 3;
  padding-top: 200px;
  padding-left: 60px;
  padding-right: 60px;
  mix-blend-mode: normal;

  ${({ theme }) => theme.adaptive.dashboard.xxl} {
    padding-left: 50px;
    padding-right: 50px;
  }

  ${({ theme }) => theme.adaptive.dashboard.xl} {
    padding-left: 45px;
    padding-right: 45px;
    padding-top: 180px;
  }

  ${({ theme }) => theme.adaptive.dashboard.lg} {
    padding-top: 140px;
  }

  ${({ theme }) => theme.adaptive.dashboard.md} {
    padding-left: 26px;
    padding-right: 26px;
  }
`

const TitleRow = styled.div`
  width: 1800px;
  margin: 0 auto;

  ${({ theme }) => theme.adaptive.dashboard.xxl} {
    width: 1580px;
  }

  ${({ theme }) => theme.adaptive.dashboard.xl} {
    width: 1350px;
  }

  ${({ theme }) => theme.adaptive.dashboard.lg} {
    width: 1190px;
  }

  ${({ theme }) => theme.adaptive.dashboard.md} {
    width: 874px;
  }

  ${({ theme }) => theme.adaptive.dashboard.sm} {
    width: 326px;
    padding-left: 4px;
    padding-right: 4px;

    margin-bottom: 30px;

    flex-direction: column;
  }

  padding-left: 60px;

  display: flex;
  align-items: baseline;
`

const SubTitleBlock = styled.div`
  margin-left: 20px;

  font-size: 17px;
  line-height: 100%;

  ${({ theme }) => theme.adaptive.dashboard.xl} {
    font-size: 15px;
  }

  ${({ theme }) => theme.adaptive.dashboard.sm} {
    margin-left: 0;

    font-size: 13px;
    line-height: 1.2;
  }

  color: #939ba9;
`

const SubTitle = styled.h3`
  margin: 0;

  font-weight: 300;
`
