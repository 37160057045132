import React from 'react'
import { BrowserRouter as RouterReact, Switch, Route } from 'react-router-dom'
import Dashboard from './Dashboard'
import ScrollToTop from './ScrollToTop'
import OccamX from '../components/Occamx/new'

export default function Router() {
  return (
    <RouterReact>
      <ScrollToTop>
        <Switch>
          <Route exact path="/">
            <OccamX />
          </Route>
          <Route path="/ocx_board">
            <Dashboard />
          </Route>
        </Switch>
      </ScrollToTop>
    </RouterReact>
  )
}
