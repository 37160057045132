import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { format, parseISO } from 'date-fns'
import useWindowSize from 'helpers/utils/useWindowSize'
import getLatestMediumPosts from 'api/medium/medium'
import Slider from 'react-slick'
// @ts-ignore
import LazyLoad from 'react-lazyload'
import occamLogo from 'img/new/news/occam-logo.svg'
import arrowImg from 'img/new/news/arrow.svg'
import ellipse from '../../../../img/new/main/elipse.svg'

const News: React.FunctionComponent = () => {
  const [newsList, setNewsList] = useState([])
  const [postsFetched, setPostFetched] = useState(false)
  const { width } = useWindowSize()

  const settings = {
    centerMode: false,
    infinite: false,
    speed: 500,
    arrows: false,
    swipeToSlide: true,
    slidesToShow: 3,
    variableWidth: true,
    responsive: [
      {
        breakpoint: 860,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  }

  // @ts-ignore
  useEffect(async () => {
    const posts = await getLatestMediumPosts()
    setNewsList(posts.data)
    setPostFetched(true)
  }, [])

  const truncateByChars = (str: string, max: number) => {
    return str.length > max ? str.substring(0, max) + '...' : str
  }

  const parseArticleDate = (date: string) => {
    try {
      return format(parseISO(date), 'MMM dd, yyyy')
    } catch (error) {
      console.log(error)
      return ''
    }
  }

  const itemsList = newsList.slice(0, 5).map((item: any, idx) => {
    let count = 5
    if (width <= 580) {
      count = 4
    }
    if (idx !== 0 && idx < count) {
      if (postsFetched) {
        return (
          <Article key={idx}>
            <ArticleLink target={'_blank'} href={item.link}>
              <LazyLoad height={250} offset={300} once>
                <Img src={item.thumbnail} />
              </LazyLoad>
              <Date>{parseArticleDate(item.isoDate)}</Date>
              <ArticleTitle>{item.title}</ArticleTitle>
              <ArticleText>
                {truncateByChars(item.description, 150)}
              </ArticleText>
            </ArticleLink>
          </Article>
        )
      }
    }
  })

  return (
    <Root>
      <EllipseBlur />
      <Blur />
      <Cont>
        <Title>News and Releases</Title>
        <SliderWrap>
          <Slider {...settings} className="association-slider">
            {itemsList}
          </Slider>
        </SliderWrap>
        <BottomRow>
          <OccamLogo />
          <NewsLink target={'_blank'} href={'https://medium.com/occam-finance'}>
            View All
          </NewsLink>
        </BottomRow>
      </Cont>
    </Root>
  )
}

export default News

const Root = styled.div`
  position: relative;
  width: 100%;
  padding-top: 249px;
  ${({ theme }) => theme.adaptive.newDesign.xl} {
    padding-top: 216px;
  }
  ${({ theme }) => theme.adaptive.newDesign.lg} {
    padding-top: 186px;
  }
  ${({ theme }) => theme.adaptive.newDesign.slg} {
    padding-top: 159px;
  }
  ${({ theme }) => theme.adaptive.newDesign.md} {
    padding-top: 119px;
  }
  ${({ theme }) => theme.adaptive.newDesign.sm} {
    padding-top: 72px;
  }
  ${({ theme }) => theme.adaptive.newDesign.smm} {
    padding-top: 83px;
  }
  ${({ theme }) => theme.adaptive.newDesign.xs} {
    padding-top: 67px;
  }
`

const Cont = styled.div`
  position: relative;
  width: 1598px;
  height: 864px;
  padding: 58px 0 0 65px;
  ${({ theme }) => theme.adaptive.newDesign.xl} {
    width: 1399px;
    height: 756px;
    padding: 51px 0 0 57px;
  }
  ${({ theme }) => theme.adaptive.newDesign.lg} {
    width: 1199px;
    height: 648px;
    padding: 43px 0 0 49px;
  }

  ${({ theme }) => theme.adaptive.newDesign.slg} {
    width: 1034px;
    height: 558px;
    padding: 37px 0 0 43px;
  }
  ${({ theme }) => theme.adaptive.newDesign.md} {
    width: 773px;
    height: 464px;
    padding: 28px 0 0 32px;
  }
  ${({ theme }) => theme.adaptive.newDesign.sm} {
    width: 520px;
    height: 617px;
    padding: 28px 0 0 30px;
  }
  ${({ theme }) => theme.adaptive.newDesign.smm} {
    width: 335px;
    height: 596px;
    padding: 32px 0 43px 23px;
  }
  ${({ theme }) => theme.adaptive.newDesign.xs} {
    width: 280px;
    height: 465px;
    padding: 27px 0 34px 14px;
  }
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100%;
    background: rgba(218, 242, 247, 0.07);
    border-radius: 14px;
    ${({ theme }) => theme.adaptive.newDesign.md} {
      border-radius: 10px;
    }
  }
`

const Title = styled.div`
  position: relative;
  font-weight: 200;
  font-size: 55px;
  line-height: 66px;
  color: #ffffff;
  margin-bottom: 43px;
  ${({ theme }) => theme.adaptive.newDesign.xl} {
    font-size: 50px;
    line-height: 60px;
    margin-bottom: 43px;
  }
  ${({ theme }) => theme.adaptive.newDesign.lg} {
    font-size: 44px;
    line-height: 52px;
    margin-bottom: 37px;
  }
  ${({ theme }) => theme.adaptive.newDesign.slg} {
    font-size: 38px;
    line-height: 44px;
    margin-bottom: 31px;
  }
  ${({ theme }) => theme.adaptive.newDesign.md} {
    font-size: 30px;
    line-height: 36px;
    margin-bottom: 22px;
  }
  ${({ theme }) => theme.adaptive.newDesign.smm} {
    font-size: 26px;
    line-height: 140%;
    margin-bottom: 25px;
  }
  ${({ theme }) => theme.adaptive.newDesign.xs} {
    font-size: 24px;
    margin-bottom: 17px;
  }
`

const Article = styled.div`
  position: relative;
  padding-right: 120px;
  ${({ theme }) => theme.adaptive.newDesign.xl} {
    padding-right: 106px;
  }
  ${({ theme }) => theme.adaptive.newDesign.lg} {
    padding-right: 91px;
  }
  ${({ theme }) => theme.adaptive.newDesign.slg} {
    padding-right: 77px;
  }
  ${({ theme }) => theme.adaptive.newDesign.md} {
    padding-right: 58px;
  }
  ${({ theme }) => theme.adaptive.newDesign.sm} {
    padding-right: 60px;
  }
  ${({ theme }) => theme.adaptive.newDesign.xs} {
    padding-right: 46px;
  }
  &:before {
    content: '';
    position: absolute;
    width: 1px;
    height: 76px;
    top: 106px;
    right: 57px;
    transform: translateY(-50%);
    opacity: 0.18;
    background: #fff;
    ${({ theme }) => theme.adaptive.newDesign.xl} {
      width: 1px;
      height: 67px;
      right: 53px;
    }
    ${({ theme }) => theme.adaptive.newDesign.lg} {
      width: 1px;
      height: 57px;
      right: 47px;
    }
    ${({ theme }) => theme.adaptive.newDesign.slg} {
      width: 1px;
      height: 49px;
      top: 90px;
      right: 40px;
    }
    ${({ theme }) => theme.adaptive.newDesign.md} {
      width: 1px;
      height: 36px;
      top: 90px;
      right: 30px;
    }
    ${({ theme }) => theme.adaptive.newDesign.sm} {
      width: 1px;
      height: 70px;
      top: 100px;
      right: 30px;
    }
    ${({ theme }) => theme.adaptive.newDesign.smm} {
      top: 90px;
    }
    ${({ theme }) => theme.adaptive.newDesign.xs} {
      height: 55px;
      right: 24px;
      top: 70px;
    }
  }
`

const ArticleLink = styled.a`
  position: relative;
  display: block;
`

const Img = styled.img`
  position: relative;
  display: block;
  width: 436px;
  height: 287px;
  border: 1px solid rgba(0, 0, 0, 0.06);
  border-radius: 10px;
  margin-bottom: 40px;
  object-fit: cover;
  ${({ theme }) => theme.adaptive.newDesign.xl} {
    width: 380px;
    height: 250px;
    border-radius: 10px;
    margin-bottom: 34px;
  }
  ${({ theme }) => theme.adaptive.newDesign.lg} {
    width: 326px;
    height: 215px;
    border-radius: 8px;
    margin-bottom: 29px;
  }
  ${({ theme }) => theme.adaptive.newDesign.slg} {
    width: 282px;
    height: 185px;
    border-radius: 6px;
    margin-bottom: 26px;
  }
  ${({ theme }) => theme.adaptive.newDesign.md} {
    width: 209px;
    height: 137px;
    border-radius: 6px;
    margin-bottom: 18px;
  }
  ${({ theme }) => theme.adaptive.newDesign.sm} {
    width: 301px;
    height: 222px;
    border-radius: 8px;
    margin-bottom: 26px;
  }
  ${({ theme }) => theme.adaptive.newDesign.smm} {
    width: 264px;
    height: 195px;
    border-radius: 7px;
    margin-bottom: 22px;
  }
  ${({ theme }) => theme.adaptive.newDesign.xs} {
    width: 206px;
    height: 152px;
  }
`

const Date = styled.div`
  font-weight: 300;
  font-size: 13px;
  line-height: 18px;
  color: #538fe7;
  margin-bottom: 17px;
  ${({ theme }) => theme.adaptive.newDesign.xl} {
    font-size: 13px;
    line-height: 16px;
    margin-bottom: 16px;
  }
  ${({ theme }) => theme.adaptive.newDesign.lg} {
    margin-bottom: 13px;
  }
  ${({ theme }) => theme.adaptive.newDesign.slg} {
    margin-bottom: 10px;
  }
  ${({ theme }) => theme.adaptive.newDesign.md} {
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 9px;
  }
  ${({ theme }) => theme.adaptive.newDesign.sm} {
    font-size: 16px;
    line-height: 16px;
    margin-bottom: 16px;
  }
  ${({ theme }) => theme.adaptive.newDesign.xs} {
    font-size: 13px;
    line-height: 12px;
    margin-bottom: 8px;
  }
`

const ArticleTitle = styled.div`
  font-weight: 300;
  font-size: 34px;
  line-height: 38px;
  color: #fff;
  margin-bottom: 26px;
  ${({ theme }) => theme.adaptive.newDesign.xl} {
    font-size: 30px;
    line-height: 34px;
    margin-bottom: 24px;
  }
  ${({ theme }) => theme.adaptive.newDesign.lg} {
    font-size: 26px;
    line-height: 30px;
    margin-bottom: 21px;
  }
  ${({ theme }) => theme.adaptive.newDesign.slg} {
    font-size: 22px;
    line-height: 26px;
    margin-bottom: 17px;
  }
  ${({ theme }) => theme.adaptive.newDesign.md} {
    font-size: 16px;
    line-height: 17px;
    margin-bottom: 13px;
  }
  ${({ theme }) => theme.adaptive.newDesign.sm} {
    font-size: 20px;
    line-height: 110%;
    margin-bottom: 12px;
  }
  ${({ theme }) => theme.adaptive.newDesign.xs} {
    font-size: 16px;
    margin-bottom: 10px;
  }
`

const ArticleText = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  color: #c3c3c3;
  opacity: 0.5;
  ${({ theme }) => theme.adaptive.newDesign.xl} {
    font-size: 15px;
  }
  ${({ theme }) => theme.adaptive.newDesign.lg} {
    font-size: 13px;
  }
  ${({ theme }) => theme.adaptive.newDesign.slg} {
  }
  ${({ theme }) => theme.adaptive.newDesign.md} {
    font-size: 12px;
  }
  ${({ theme }) => theme.adaptive.newDesign.sm} {
    font-size: 16px;
  }
  ${({ theme }) => theme.adaptive.newDesign.xs} {
    font-size: 13px;
  }
`
const SliderWrap = styled.div`
  position: relative;
  width: 100vw;
  .slick-slide {
    width: 556px !important;
    ${({ theme }) => theme.adaptive.newDesign.xl} {
      width: 486px !important;
    }
    ${({ theme }) => theme.adaptive.newDesign.lg} {
      width: 417px !important;
    }
    ${({ theme }) => theme.adaptive.newDesign.slg} {
      width: 359px !important;
    }
    ${({ theme }) => theme.adaptive.newDesign.md} {
      width: 267px !important;
    }
    ${({ theme }) => theme.adaptive.newDesign.sm} {
      width: 361px !important;
    }
    ${({ theme }) => theme.adaptive.newDesign.smm} {
      width: 324px !important;
    }
    ${({ theme }) => theme.adaptive.newDesign.xs} {
      width: 253px !important;
    }
  }
`

const BottomRow = styled.div`
  position: absolute;
  bottom: 44px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: space-between;
  width: calc(100% - 130px);
  ${({ theme }) => theme.adaptive.newDesign.xl} {
    bottom: 40px;
    width: calc(100% - 110px);
  }
  ${({ theme }) => theme.adaptive.newDesign.lg} {
    bottom: 31px;
    width: calc(100% - 94px);
  }
  ${({ theme }) => theme.adaptive.newDesign.slg} {
    bottom: 23px;
    width: calc(100% - 82px);
  }
  ${({ theme }) => theme.adaptive.newDesign.md} {
    bottom: 25px;
    width: calc(100% - 60px);
  }
  ${({ theme }) => theme.adaptive.newDesign.sm} {
    bottom: 30px;
    width: calc(100% - 60px);
  }
  ${({ theme }) => theme.adaptive.newDesign.smm} {
    bottom: 43px;
    width: calc(100% - 46px);
  }
  ${({ theme }) => theme.adaptive.newDesign.xs} {
    bottom: 34px;
    width: calc(100% - 28px);
  }
`

const OccamLogo = styled.div`
  position: relative;
  width: 25px;
  height: 17px;
  background: url('${occamLogo}') center no-repeat;
  background-size: cover;
`

const NewsLink = styled.a`
  position: relative;
  display: block;
  font-weight: 300;
  font-size: 16px;
  line-height: 16px;
  height: 16px;
  color: #ffffff;
  ${({ theme }) => theme.adaptive.newDesign.md} {
    font-size: 14px;
    line-height: 14px;
    height: 14px;
  }
  ${({ theme }) => theme.adaptive.newDesign.smm} {
    font-size: 16px;
    line-height: 16px;
    margin-right: 30px;
    height: 16px;
  }
  ${({ theme }) => theme.adaptive.newDesign.xs} {
    font-size: 14px;
    line-height: 14px;
    margin-right: 27px;
    height: 14px;
  }
  &:before {
    content: '';
    position: absolute;
    width: 21px;
    height: 6px;
    right: -33px;
    top: 50%;
    transform: translateY(-50%);
    background: url('${arrowImg}') center no-repeat;
    background-size: cover;
    ${({ theme }) => theme.adaptive.newDesign.xs} {
      width: 18px;
      height: 5px;
      right: -27px;
    }
  }
`

const EllipseBlur = styled.div`
  position: absolute;
  top: -60%;
  right: -40%;
  width: 1801px;
  height: 1740px;
  background: url('${ellipse}') center no-repeat;
  background-size: cover;
  filter: blur(220px);
  transform: translate3d(0, 0, 0);
  ${({ theme }) => theme.adaptive.newDesign.xl} {
    width: 1576px;
    height: 1523px;
    filter: blur(193px);
  }
  ${({ theme }) => theme.adaptive.newDesign.lg} {
    width: 1351px;
    height: 1305px;
    filter: blur(165px);
  }
  ${({ theme }) => theme.adaptive.newDesign.slg} {
    width: 1164px;
    height: 1125px;
    filter: blur(142px);
  }
  ${({ theme }) => theme.adaptive.newDesign.md} {
    width: 864px;
    height: 835px;
    filter: blur(105px);
  }
  ${({ theme }) => theme.adaptive.newDesign.sm} {
    top: unset;
    bottom: -22%;
    right: unset;
    left: -60%;
  }
  ${({ theme }) => theme.adaptive.newDesign.smm} {
    bottom: -31%;
    left: -115%;
    filter: blur(82px);
  }
`

const Blur = styled.div`
  position: absolute;
  bottom: -216px;
  left: 100px;
  width: 443px;
  height: 443px;
  background: #6ca7ff;
  opacity: 0.5;
  filter: blur(300px);
  transform: translate3d(0, 0, 0);
  ${({ theme }) => theme.adaptive.newDesign.xl} {
    bottom: -189px;
    left: 88px;
    width: 388px;
    height: 388px;
    filter: blur(260px);
  }
  ${({ theme }) => theme.adaptive.newDesign.lg} {
    bottom: -162px;
    left: 75px;
    width: 332px;
    height: 332px;
    filter: blur(260px);
  }
  ${({ theme }) => theme.adaptive.newDesign.slg} {
    bottom: -100px;
    left: 65px;
    width: 286px;
    height: 286px;
    filter: blur(174px);
  }
  ${({ theme }) => theme.adaptive.newDesign.md} {
    bottom: -65px;
    left: 48px;
    width: 212px;
    height: 212px;
    filter: blur(143px);
  }
  ${({ theme }) => theme.adaptive.newDesign.sm} {
    bottom: 253px;
    left: -109px;
    width: 393px;
    height: 393px;
    filter: blur(120px);
  }
  ${({ theme }) => theme.adaptive.newDesign.smm} {
    display: none;
  }
`
