export const numberFormatterWithCommaGrouping = new Intl.NumberFormat('en-US', {
  useGrouping: true,
})
export const numberFormatterWithSpaceGrouping = new Intl.NumberFormat('ru-RU', {
  useGrouping: true,
})
export const numberFormatterWithLocaleGrouping = new Intl.NumberFormat(
  undefined,
  { useGrouping: true }
)

export const percentFormatterLocaleAware = new Intl.NumberFormat(undefined, {
  style: 'percent',
  maximumFractionDigits: 1,
})
