import React, { useState } from 'react'
import styled from 'styled-components'
import partnersList from 'store/partners'
import PartnersItem from './PartnersItem'
import Ticker from 'react-ticker'
import PageVisibility from 'react-page-visibility'

const Partners: React.FunctionComponent = () => {
  const [isPlay, setIsPlay] = useState(true)
  const [pageIsVisible, setPageIsVisible] = useState(true)

  const handleVisibilityChange = (
    isVisible: boolean | ((prevState: boolean) => boolean)
  ) => {
    setPageIsVisible(isVisible)
  }

  const onEnter = () => {
    setIsPlay(false)
  }

  const onLeave = () => {
    setIsPlay(true)
  }

  return (
    <PartnersWrapper>
      <PartnersContainer>
        <PartnersTitle>The Partners</PartnersTitle>
      </PartnersContainer>
      <PageVisibility onChange={handleVisibilityChange}>
        {pageIsVisible && (
          <Ticker move={isPlay}>
            {({ index }) => (
              <SliderContainer
                onMouseEnter={onEnter}
                onMouseLeave={onLeave}
                count={Math.ceil(partnersList.length / 2)}
              >
                {partnersList.map((partner, idx) => {
                  return (
                    <PartnersItem
                      key={idx}
                      link={partner.link}
                      imageSrc={partner.getLogo('#FFFFFF')}
                    />
                  )
                })}
              </SliderContainer>
            )}
          </Ticker>
        )}
      </PageVisibility>
    </PartnersWrapper>
  )
}

export default Partners

interface sliderProps {
  count: number
}

const PartnersWrapper = styled.div`
  position: relative;
  width: 100%;
  margin-top: 180px;
  z-index: 2;
  ${({ theme }) => theme.adaptive.newDesign.xl} {
    margin-top: 156px;
  }
  ${({ theme }) => theme.adaptive.newDesign.lg} {
    margin-top: 135px;
  }
  ${({ theme }) => theme.adaptive.newDesign.slg} {
    margin-top: 76px;
  }
  ${({ theme }) => theme.adaptive.newDesign.md} {
    margin-top: 45px;
  }
  ${({ theme }) => theme.adaptive.newDesign.sm} {
    margin-top: 126px;
  }
  ${({ theme }) => theme.adaptive.newDesign.smm} {
    margin-top: 126px;
  }
  ${({ theme }) => theme.adaptive.newDesign.xs} {
    margin-top: 98px;
  }
`

const PartnersContainer = styled.div`
  position: relative;
  width: 1598px;
  margin: 0 auto;
  z-index: 2;
  display: flex;
  align-items: center;
  ${({ theme }) => theme.adaptive.newDesign.xl} {
    width: 1399px;
  }
  ${({ theme }) => theme.adaptive.newDesign.lg} {
    width: 1199px;
  }
  ${({ theme }) => theme.adaptive.newDesign.slg} {
    width: 1034px;
  }
  ${({ theme }) => theme.adaptive.newDesign.md} {
    width: 773px;
  }
  ${({ theme }) => theme.adaptive.newDesign.sm} {
    width: 520px;
  }
  ${({ theme }) => theme.adaptive.newDesign.smm} {
    width: 335px;
  }
  ${({ theme }) => theme.adaptive.newDesign.xs} {
    width: 280px;
  }
`

const PartnersTitle = styled.div`
  font-weight: 200;
  font-size: 55px;
  color: #ebebeb;
  ${({ theme }) => theme.adaptive.newDesign.xl} {
    font-size: 50px;
    line-height: 56px;
  }
  ${({ theme }) => theme.adaptive.newDesign.lg} {
    font-size: 44px;
    line-height: 52px;
  }
  ${({ theme }) => theme.adaptive.newDesign.slg} {
    font-size: 38px;
    line-height: 46px;
  }
  ${({ theme }) => theme.adaptive.newDesign.md} {
    font-size: 30px;
    line-height: 36px;
  }
  ${({ theme }) => theme.adaptive.newDesign.smm} {
    font-size: 26px;
    line-height: 140%;
  }
  ${({ theme }) => theme.adaptive.newDesign.xs} {
    font-size: 24px;
    line-height: 24px;
  }
`

const SliderContainer = styled.div<sliderProps>`
  position: relative;
  display: flex;
  width: 100%;
  margin-top: 61px;
  ${({ theme }) => theme.adaptive.newDesign.xl} {
    margin-top: 56px;
  }
  ${({ theme }) => theme.adaptive.newDesign.lg} {
    margin-top: 48px;
  }
  ${({ theme }) => theme.adaptive.newDesign.slg} {
    margin-top: 40px;
  }
  ${({ theme }) => theme.adaptive.newDesign.md} {
    margin-top: 31px;
  }
  ${({ theme }) => theme.adaptive.newDesign.smm} {
    margin-top: 38px;
  }
  ${({ theme }) => theme.adaptive.newDesign.xs} {
    margin-top: 31px;
  }
`
