import styled from 'styled-components'

export const Container = styled.div`
  width: 1700px;
  margin: 0 auto;

  ${({ theme }) => theme.adaptive.newDesign.xl} {
    width: 1488px;
  }

  ${({ theme }) => theme.adaptive.newDesign.lg} {
    width: 1275px;
  }

  ${({ theme }) => theme.adaptive.newDesign.slg} {
    width: 1098px;
  }

  ${({ theme }) => theme.adaptive.newDesign.md} {
    width: 814px;
  }

  ${({ theme }) => theme.adaptive.newDesign.sm} {
    width: 540px;
  }

  ${({ theme }) => theme.adaptive.newDesign.smm} {
    width: 349px;
  }

  ${({ theme }) => theme.adaptive.newDesign.xs} {
    width: 100%;
    padding: 0 20px;
  }
`

export const TitleH2 = styled.h2`
  font-weight: 100;
  font-size: 68px;
  line-height: 82px;
  color: #ebebeb;
  text-align: center;
  margin: 0;

  ${({ theme }) => theme.adaptive.occamx.xl} {
    font-size: 60px;
    line-height: 72px;
  }

  ${({ theme }) => theme.adaptive.occamx.lg} {
    font-size: 50px;
    line-height: 60px;
  }

  ${({ theme }) => theme.adaptive.occamx.slg} {
    font-size: 44px;
    line-height: 53px;
  }

  ${({ theme }) => theme.adaptive.occamx.md} {
    font-size: 32px;
    line-height: 38px;
  }

  ${({ theme }) => theme.adaptive.occamx.smd} {
    font-size: 33px;
    line-height: 40px;
  }

  ${({ theme }) => theme.adaptive.occamx.sm} {
    font-size: 33px;
    line-height: 40px;
  }

  ${({ theme }) => theme.adaptive.occamx.sm} {
    font-size: 33px;
    line-height: 40px;
  }
`
