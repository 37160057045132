import React from 'react'

import styled, { css } from 'styled-components'
import CountUp from 'react-countup'

import { numberFormatterWithLocaleGrouping } from '../../helpers/utils/numberFormatters'

export default function Counter({ small, big, noSkipHoles, ...props }) {
  return (
    <StyledCounter
      small={small}
      big={big}
      duration={5}
      preserveValue
      formattingFn={numberFormatterWithLocaleGrouping.format}
      {...props}
    />
  )
}

const StyledCounter = styled(CountUp)`
  font-size: 48px;

  ${({ theme }) => theme.adaptive.dashboard.xl} {
    font-size: 41px;
  }

  ${({ theme }) => theme.adaptive.dashboard.lg} {
    font-size: 36px;
  }

  ${({ theme }) => theme.adaptive.dashboard.sm} {
    font-size: 53px;
  }

  ${({ small }) =>
    small &&
    css`
      font-size: 33px;

      ${({ theme }) => theme.adaptive.dashboard.xl} {
        font-size: 28px;
      }

      ${({ theme }) => theme.adaptive.dashboard.lg} {
        font-size: 24px;
      }

      ${({ theme }) => theme.adaptive.dashboard.sm} {
        font-size: 29px;
      }
    `}

  ${({ big }) =>
    big &&
    css`
      font-size: 96px;

      ${({ theme }) => theme.adaptive.dashboard.xxl} {
        font-size: 78px;
      }

      ${({ theme }) => theme.adaptive.dashboard.xl} {
        font-size: 66px;
      }

      ${({ theme }) => theme.adaptive.dashboard.lg} {
        font-size: 55px;
      }
    `}

  text-align: right;

  font-family: 'Roboto Mono', monospace;
  font-weight: 200;
  color: #ffffff;
`
