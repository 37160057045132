export const OCCAM_LINK = 'https://occam.fi'
export const OCCAMX_DECK = 'https://docsend.com/view/86wmmuqjuffm3fgv'

export const APPLY_IDO_LINK = 'https://forms.gle/tztXqpjcSWmS9TjE6'
export const FAQ_LINK = 'https://occamx.gitbook.io/occamx-faq/'
export const OCCAMX_LINK = 'https://occam-x.fi'

export const RAZER_LINK = 'https://occam.fi/razer'
export const INCUBATOR_LINK = 'https://occam.fi/incubator'
export const DAO_LINK = 'https://occam.fi/vcdao'
export const ISPO_LINK = 'https://forms.gle/nmvr1gLBujU5j4VW9'
export const DAPP_LINK = 'https://app.occamx.fi/'
export const CAREERS_LINK = 'https://angel.co/company/occam-fi/jobs'
